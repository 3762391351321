import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import menuItems from 'menu-items';
import config from 'config';
import { getLastSegment } from 'utils/getLastSegment';
import { UserLayer } from 'pages/admin/constant';
import useFeatures from 'hooks/useFeatures';
import featuresCods from 'constants/features';

const findMenuItemByUrl = (menuItems, url) => {
  const lastSeg = getLastSegment(url);

  const findMenuItem = (items) => {
    for (const item of items) {
      if (item.url && getLastSegment(item.url) === lastSeg) {
        return item;
      }

      if (item.children) {
        const foundItem = findMenuItem(item.children);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return null;
  };

  return findMenuItem(menuItems);
};

const usePermissionRedirect = () => {
  const { role, userRoleType } = useSelector(state => state.auth || {});
  const { pathname } = useLocation();
  const { hasPendingLinksFeature, hasTrustedDestinationFeature, hasELinksFeature } = useFeatures();
  const lastSeg = getLastSegment(pathname);

  // Internal redirect logic
  const rule = findMenuItemByUrl(menuItems, lastSeg);

  const hasMissingProps = !role.code;
  let featureNotAvailable = false;

  if (rule?.id === featuresCods.TRUSTED_DESTINATIONS) {
    featureNotAvailable = !hasTrustedDestinationFeature;
  } else if (rule?.id === featuresCods.PENDING_LINKS) {
    featureNotAvailable = !hasPendingLinksFeature;
  } else if (rule?.id === featuresCods.E_LINKS) {
    featureNotAvailable = !hasELinksFeature;
  }

  const shouldRedirect =
    (rule &&
      rule?.unauthorizedRoles &&
      rule.unauthorizedRoles.some(itm => itm === role.code)
    );

  if (featureNotAvailable || (userRoleType === UserLayer.Domain && (hasMissingProps || shouldRedirect))) {
    return { redirect: config.defaultPath };
  }

  return {};
};

export default usePermissionRedirect;
