import PropTypes from 'prop-types';
import { List } from '@mui/material';

import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

const NavGroup = ({ items }) => {
  let navCollapse;

  switch (items.type) {
    case 'collapse':
      navCollapse = <NavCollapse key={items.id} menu={items} level={1} />;
      break;
    case 'item':
      navCollapse = <NavItem key={items.id} item={items} level={1} />;
      break;
    default:
      navCollapse = null;
  }

  return <List sx={{ mt: 0, py: 0, zIndex: 0 }}>{navCollapse}</List>;
};

NavGroup.propTypes = {
  items: PropTypes.object,
};

export default NavGroup;
