import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import LogoMain from 'components/logo/LogoMain';
import useTenant from 'hooks/useTenant';
import config from 'config';

import NotificationsList from './Notification';
import Profile from './Profile';
import DomainSelection from './DomainSelection';

const HeaderContent = () => {
  const { tenant } = useTenant();
  const { defaultTenant } = config;
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    if (tenant !== defaultTenant) {
      setShowLogo(true);
    }
  }, [tenant, defaultTenant]);

  return (
    <Box width="100%" display="flex" justifyContent="space-between" height="61px">
      <Box py={2} px={1} sx={{ width: '235px' }}>
        {showLogo ? <LogoMain tenant /> : null}
      </Box>
      <Box>
        <DomainSelection />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <NotificationsList />
        <Profile />
      </Box>
    </Box>
  );
};

export default HeaderContent;
