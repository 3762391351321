import { pickAndRename } from 'utils/objectUtils';

import { ENDPOINTS, BE_FE_MAP, FE_BE_MAP } from './constants';

class QR {
  constructor(restService) {
    this.restService = restService;
  }

  async getOne(uuid) {
    try {
      const { data } = await this.restService.get(ENDPOINTS.getOne(uuid));

      return Promise.resolve(pickAndRename(data, BE_FE_MAP.getOne));
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async convertTypes({ sourceFormat = 'svg', targetFormat = 'eps', sourceData }) {
    try {
      const response = await this.restService.post(
        ENDPOINTS.convertTypes,
        pickAndRename({ sourceFormat, targetFormat, sourceData }, FE_BE_MAP),
      );

      return response;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  #handleError(error) {
    // return userFriendlyError <maybe>
    return Promise.reject(error);
  }
}

export default QR;
