import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTemplatesList = createAsyncThunk('templates/getTemplatesList', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/templates`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createTemplate = createAsyncThunk(
  'templates/createTemplate',
  async ({ templateData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/templates`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editTemplate = createAsyncThunk(
  'templates/editTemplate',
  async ({ templateData = {}, templateID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/templates/${templateID}`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeTemplate = createAsyncThunk(
  'templates/removeTemplate',
  async ({ templateID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/templates/${templateID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
