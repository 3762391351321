import qs from 'qs';

// get params from browser URL
const getParams = (defaultPropsParam) => {
  const {
    page = defaultPropsParam ? defaultPropsParam.page : 1,
    count = defaultPropsParam ? defaultPropsParam.count : 25,
    ...others
  } = qs.parse(document.location.search, { ignoreQueryPrefix: true });
  const isValidPage = Number(page) >= 1;
  const isValidCount = Number(count) >= 1;

  return {
    page: isValidPage ? page : 1,
    count: isValidCount ? count : 25,
    ...others,
  };
};

export default getParams;
