// ==============================|| OVERRIDES - Filter Input ||============================== //

export default function FilterInput() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        filterForm: {
          '& .MuiFormControl-root': {
            justifyContent: 'flex-end'
          }
        }
      }
    },
  };
}
