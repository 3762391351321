/* eslint import/no-mutable-exports: 0 */
import axiosInstance from 'utils/axios';

import HTTPRest from './HTTPRest';
import ELink from './ELink/ELink';
import QR from './QR/QR';
import Image from './Image/Image';

let eLinksService;
let httpRestService;
let qrService;
let imageService;

const initServices = () => {
  httpRestService = new HTTPRest(axiosInstance);
  eLinksService = new ELink(httpRestService);
  qrService = new QR(httpRestService);
  imageService = new Image(httpRestService);
};

export { initServices, eLinksService, qrService, imageService };
