import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider as ReduxProvider } from 'react-redux';

import 'react-awesome-lightbox/build/style.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';
import 'react-phone-input-2/lib/material.css';
import './index.css';

// load mock apis
import '_api';

// project import
import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import config from './config';
import reportWebVitals from './reportWebVitals';

import 'utils/arrayUtils';
import noop from 'lodash/noop';

import axiosInstance from './utils/axios';
import getTenant from './utils/getTenant';
import getParams from './utils/getParams';

const container = document.getElementById('root');
const root = createRoot(container);
const { auth0Domain, auth0ClientID } = config;

const getOrganizationID = async () => {
  const currentHost = new URL(window.location.href.toString()).hostname;
  const tenant = getTenant(currentHost.split('.')[0] || ''); // Get the top level subdomain

  const response = await axiosInstance.get(`/auth0-organization`, {
    params: {
      tenant,
    },
  });

  const { organization_id: organizationId } = response.data;

  return organizationId;
};

if (['local', 'ci', 'testing', 'test'].includes(config.environment) === false) {
  Sentry.init({
    dsn: 'https://12249dbb8b6a480ea397c23b29575c37@o4504634800209920.ingest.sentry.io/4504766859706368',
    integrations: [new BrowserTracing()],
    environment: config.environment || 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  Sentry.setTag('environment', config.environment);
}

getOrganizationID().then((currentOrganizationID) => {
  const { blink_id = null } = getParams();
  const state = blink_id ? `?blink_id=${blink_id}` : '';
  const windowPath = window.location.pathname !== '/' ? window.location.pathname : config.defaultPath;

  if (!currentOrganizationID) {
    root.render(
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientID}
        redirectUri={window.location.origin + windowPath + state}
        useRefreshTokens
        cacheLocation={'localstorage'}
        onRedirectCallback={noop}
      >
        <ReduxProvider store={store}>
          <ConfigProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </ReduxProvider>
      </Auth0Provider>,
    );
  } else {
    root.render(
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientID}
        redirectUri={window.location.origin + windowPath + state}
        useRefreshTokens
        organization={currentOrganizationID}
        cacheLocation={'localstorage'}
        onRedirectCallback={noop}
      >
        <ReduxProvider store={store}>
          <ConfigProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </ReduxProvider>
      </Auth0Provider>,
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bl.ink/CRA-vitals
reportWebVitals();
