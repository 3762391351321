export const tokenRefreshErrorStatus = 406;

export const chartColors = [
  '#453F91',
  '#786EEE',
  '#19B08A',
  '#76E1E9',
  '#F857AE',
  '#2FA1D3',
  '#786EEE',
  '#F4885C',
  '#FAAD14',
  '#76E1E9',
  '#6BBADD',
];
