import config from 'config';
import { getTenantsList } from '_api/tenants';
import { createSlice } from '@reduxjs/toolkit';

const defaultTenant = config.defaultTenant || '';

const initialState = {
  tenant: defaultTenant,
  isTenantSite: false,
  tenantsList: [],
  getTenantsListLoading: false,
  getTenantsListSuccess: false,
  getTenantsListError: false,
};

const tenant = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setIsTenantSite: (state, action) => {
      state.isTenantSite = action.payload;
    },
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
  },
  extraReducers: {
    [getTenantsList.pending]: state => ({
      ...state,
      getTenantsListLoading: true,
      getTenantsListSuccess: false,
      getTenantsListError: false,
    }),
    [getTenantsList.fulfilled]: (state, action) => {
      const { data: tenantsList = [] } = action.payload ?? {};

      return {
        ...state,
        tenantsList,
        getTenantsListLoading: false,
        getTenantsListSuccess: true,
        getTenantsListError: false,
      };
    },
    [getTenantsList.rejected]: state => ({
      ...state,
      getTenantsListLoading: false,
      getTenantsListSuccess: false,
      getTenantsListError: true,
    }),
  },
});

export default tenant.reducer;

export const { setIsTenantSite, setTenant } = tenant.actions;
