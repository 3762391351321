import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import config from 'config';

import LogoIcon from './LogoIcon';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    <LogoIcon reverse={reverse} />
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
