import { createSlice } from '@reduxjs/toolkit';
import { getSearchParams } from 'utils/urls';

const defaultFilters = [];

const searchParams = getSearchParams();
const initialState = {
  links: {
    filters: defaultFilters,
    searchValue: '',
    sortData: {},
    filterLink: 'and',
    page: searchParams.page ? Number(searchParams.page) : 0,
    perPage: searchParams.count ? Number(searchParams.count) : 25,
  },
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setLinkFilters: (state, action) => {
      state.links.filters = action.payload;
    },
    setLinkSearchValue: (state, action) => {
      state.links.searchValue = action.payload;
    },
    setLinkSortData: (state, action) => {
      state.links.sortData = action.payload;
    },
    setLinkFilterLink: (state, action) => {
      state.links.filterLink = action.payload;
    },
    setLinkPage: (state, action) => {
      state.links.page = action.payload;
    },
    setLinkPerPage: (state, action) => {
      state.links.perPage = action.payload;
    },
    resetFilters: (state) => {
      state.links = initialState.links;
    },
  },
});

export default filters.reducer;

export const {
  setLinkFilters,
  setLinkSearchValue,
  setLinkSortData,
  setLinkPage,
  setLinkPerPage,
  setLinkFilterLink,
  resetFilters,
} = filters.actions;
