import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTableViewsList = createAsyncThunk(
  'tableViews/getTableViewsList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/${domainID}/views`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createTableView = createAsyncThunk(
  'tableViews/createTableView',
  async (tableViewData, { rejectWithValue, getState }) => {
    const { name, type, tableData: table_data } = tableViewData;

    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/views`, { name, type, table_data });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTableView = createAsyncThunk(
  'tableViews/updateTableView',
  async (tableViewData, { rejectWithValue, getState }) => {
    const { name, type, id, table_data } = tableViewData;

    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.put(`/${domainID}/views/${id}`, {
        name,
        type,
        table_data,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTableView = createAsyncThunk(
  'tableViews/deleteTableView',
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.delete(`/${domainID}/views/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDefaultTableView = createAsyncThunk(
  'tableViews/getDefaultTableView',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/${domainID}/default-view`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setDefaultTableView = createAsyncThunk(
  'tableViews/setDefaultTableView',
  async ({ default_view }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/default-view`, {
        default_view,
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
