import { createSlice } from '@reduxjs/toolkit';

import {
  createTableView,
  deleteTableView,
  getDefaultTableView,
  getTableViewsList,
  updateTableView,
  setDefaultTableView,
} from '_api/tableViews';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  tableViewsList: [],
  tableViewsListStatus: XHR_STATUS.idle,
  tableViewsListMessage: '',

  activeTableViewId: null,
  activeTableViewStatus: XHR_STATUS.idle,
  activeTableViewMessage: '',

  createTableViewStatus: XHR_STATUS.idle,
  createTableViewMessage: '',

  updateTableViewStatus: XHR_STATUS.idle,
  updateTableViewMessage: '',

  deleteTableViewStatus: XHR_STATUS.idle,
  deleteTableViewMessage: '',
};

const tableViews = createSlice({
  name: 'tableViews',
  initialState,
  reducers: {
    resetActiveTableView: (state) => {
      state.activeTableViewId = null;
    },
    setActiveTableView: (state, action) => {
      state.activeTableViewId = action.payload.id;
    },
  },
  extraReducers: {
    [getTableViewsList.pending]: (state) => {
      state.tableViewsListStatus = XHR_STATUS.pending;
      state.tableViewsListMessage = '';
    },
    [getTableViewsList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.tableViewsList = data;
      state.tableViewsListStatus = XHR_STATUS.fulfilled;
      state.ableViewsListMessage = message;
    },
    [getTableViewsList.rejected]: (state, action) => {
      const { message } = action.payload;

      state.tableViewsListStatus = XHR_STATUS.rejected;
      state.tableViewsListMessage = message;
    },
    [getDefaultTableView.pending]: (state) => {
      state.activeTableViewStatus = XHR_STATUS.pending;
      state.activeTableViewMessage = '';
    },
    [getDefaultTableView.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.activeTableViewId = data?.id;
      state.activeTableViewStatus = XHR_STATUS.fulfilled;
      state.activeTableViewMessage = message;
    },
    [getDefaultTableView.rejected]: (state, action) => {
      const { message } = action.payload;

      state.activeTableViewStatus = XHR_STATUS.rejected;
      state.activeTableViewMessage = message;
    },
    [createTableView.pending]: (state) => {
      state.createTableViewStatus = XHR_STATUS.pending;
      state.createTableViewMessage = '';
    },
    [createTableView.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.tableViewsList.unshift(data);
      state.activeTableViewId = data.id;
      state.createTableViewStatus = XHR_STATUS.fulfilled;
      state.createTableViewMessage = message;
    },
    [createTableView.rejected]: (state, action) => {
      const { message } = action.payload;

      state.createTableViewStatus = XHR_STATUS.rejected;
      state.createTableViewMessage = message;
    },
    [updateTableView.pending]: (state) => {
      state.updateTableViewStatus = XHR_STATUS.pending;
      state.updateTableViewMessage = '';
    },
    [updateTableView.fulfilled]: (state, action) => {
      const { data, message } = action.payload;
      const indexForUpdate = state.tableViewsList.findIndex(el => el.id === data.id);

      state.tableViewsList[indexForUpdate] = data;
      state.updateTableViewStatus = XHR_STATUS.fulfilled;
      state.updateTableViewMessage = message;
    },
    [updateTableView.rejected]: (state, action) => {
      const { message } = action.payload;

      state.updateTableViewStatus = XHR_STATUS.rejected;
      state.updateTableViewMessage = message;
    },
    [deleteTableView.pending]: (state) => {
      state.deleteTableViewStatus = XHR_STATUS.pending;
      state.deleteTableViewMessage = '';
    },
    [deleteTableView.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.tableViewsList = state.tableViewsList.filter(el => el.id !== data.id);
      state.deleteTableViewStatus = XHR_STATUS.fulfilled;
      state.deleteTableViewMessage = message;
    },
    [deleteTableView.rejected]: (state, action) => {
      const { message } = action.payload;

      state.deleteTableViewStatus = XHR_STATUS.rejected;
      state.deleteTableViewMessage = message;
    },
    [setDefaultTableView.pending]: (state) => {
      state.activeTableViewStatus = XHR_STATUS.pending;
      state.activeTableViewMessage = '';
    },
    [setDefaultTableView.fulfilled]: (state, action) => {
      const { message } = action.payload;

      state.activeTableViewStatus = XHR_STATUS.fulfilled;
      state.activeTableViewMessage = message;
    },
    [setDefaultTableView.rejected]: (state, action) => {
      const { message } = action.payload;

      state.activeTableViewStatus = XHR_STATUS.rejected;
      state.activeTableViewMessage = message;
    },
  },
});

export const { resetActiveTableView, setActiveTableView } = tableViews.actions;

export default tableViews.reducer;
