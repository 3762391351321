import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import menuItem from 'menu-items';

import NavGroup from './NavGroup';

const Navigation = () => {
  const menu = useSelector(state => state.menu);

  const { drawerOpen } = menu;

  const navGroups = menuItem.map(item => <NavGroup key={item.id} items={item} />);

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
