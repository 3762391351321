import { lazy } from 'react';

import AuthGuard from 'middleware/AuthGuard';
import MainLayout from 'layout/MainLayout';
import loadable from 'components/Loadable';

const ELinkInternal = loadable(lazy(() => import('pages/admin/ELink/ELinkEdit')));
const ELinkTemplates = loadable(lazy(() => import('pages/admin/ELink/ELinkTemplates')));
const ELinkExternal = loadable(lazy(() => import('pages/ELink')));

const ELinkRoutes = {
  path: '',
  children: [
    {
      path: '/admin/e-link',
      element: (
        <AuthGuard>
          <MainLayout>
            <ELinkTemplates />
          </MainLayout>
        </AuthGuard>
      ),
    },
    {
      path: '/admin/e-link/add',
      element: (
        <AuthGuard>
          <ELinkInternal />
        </AuthGuard>
      ),
    },
    {
      path: '/admin/e-link/:uuid/edit',
      element: (
        <AuthGuard>
          <ELinkInternal />
        </AuthGuard>
      ),
    },
    {
      path: '/e-link/:linkSlug',
      element: (
        <ELinkExternal />
      ),
    },
  ],
};

export default ELinkRoutes;
