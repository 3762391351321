class HTTPRest {
  constructor(instance) {
    this.instance = instance;
  }

  async get(endpoint, config = {}) {
    try {
      const response = await this.instance.get(endpoint, config);
      return response.data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async post(endpoint, data, config = {}) {
    try {
      const response = await this.instance.post(endpoint, data, config);
      return response.data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async put(endpoint, data, config = {}) {
    try {
      const response = await this.instance.put(endpoint, data, config);
      return response.data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async patch(endpoint, data, config = {}) {
    try {
      const response = await this.instance.patch(endpoint, data, config);
      return response.data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async delete(endpoint, config = {}) {
    try {
      const response = await this.instance.delete(endpoint, config);
      return response.data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  #handleError(error) {
    return Promise.reject(error);
  }
}

export default HTTPRest;
