import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';

export const getPendingLinksList = createAsyncThunk(
  'pendingLinks/getPendingLinksList',
  async (
    {
      currentPage = 0,
      currentRowCount = 25,
      currentSearchValue = '',
      currentFilters = [],
      currentFilterLink = 'and',
      currentSortData: { field: sort = 'created_at', sort: sort_direction = 'desc' } = {},
      isLazyLoad = false,
      domainID,
    },
    { rejectWithValue },
  ) => {
    try {
      const queryParams = {
        page: Number(currentPage),
        per_page: Number(currentRowCount),
        search: currentSearchValue,
        filters: currentFilters,
        filter_link: currentFilterLink,
        sort,
        sort_direction,
      };

      const response = await axiosInstance.get(`/${domainID}/pending-links`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });

      return { ...response.data, isLazyLoad };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getPendingLinksStatistics = createAsyncThunk(
  'pendingLinks/getPendingLinksStatistics',
  async ({ domainID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/${domainID}/pending-links/statistics`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setApproveOnce = createAsyncThunk(
  'pendingLinks/setApproveOnce',
  async ({ domainID, linkID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/${domainID}/pending-links/${linkID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setReject = createAsyncThunk(
  'pendingLinks/setReject',
  async ({ domainID, linkID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/${domainID}/pending-links/${linkID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setApprove = createAsyncThunk(
  'pendingLinks/setApprove',
  async ({ domainID, linkID, approveData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/${domainID}/pending-links/approve/${linkID}`, approveData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const renewLink = createAsyncThunk(
  'pendingLinks/renewLink',
  async ({ linkID }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();
      const response = await axiosInstance.post(`/${domainID}/pending-links/renew/${linkID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
