// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = fontFamily => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: 1.21,
    color: '#322E59'
  },
  h2: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '3rem',
    lineHeight: 1.27,
    color: '#322E59'
  },
  h3: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: 1.33,
    color: '#322E59'
  },
  h4: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: 1.4,
    color: '#322E59'
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.563rem',
    lineHeight: 1.5,
    color: '#322E59'
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.313rem',
    lineHeight: 1.57,
    color: '#322E59'
  },
  h7: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.57,
    color: '#605E70'
  },
  h8: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.57,
    color: '#322E59'
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#605E70'
  },
  body2: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 1.66,
    color: '#605E70'
  },
  body3: {
    fontWeight: 400,
    fontSize: '1.313rem',
    lineHeight: 1.57,
    color: '#605E70'
  },
  list1: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: 1.66,
    color: '#605E70'
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 1.57
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.66
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'none'
  }
});

export default Typography;
