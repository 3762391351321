import { createSlice } from '@reduxjs/toolkit';
import { createUser, getUserAllRoles, getUsersList, deleteUsers } from '_api/admin/users';
import { deleteAttribute, getAttributesList, getOneAttributeData, submitAttribute } from '_api/admin/attributes';
import cloneDeep from 'lodash/cloneDeep';
import { UserLayer } from 'pages/admin/constant';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  // Users List
  usersList: null,
  usersListStatus: XHR_STATUS.idle,
  usersListMeta: [],
  attributesList: null,
  attributesListMeta: [],
  getUsersLoading: false,
  getUsersSuccess: false,
  getUsersError: false,
  // account roles list
  accountRoles: [],
  // Create User
  createUserLoading: false,
  createUserSuccess: false,
  createUserError: false,
  // Get Attribute List
  getAttributesLoading: false,
  getAttributesSuccess: false,
  getAttributesError: false,
  // Create Attribute
  submitAttributeLoading: false,
  submitAttributeSuccess: false,
  submitAttributeError: false,
  // Delete Attribute
  deleteAttributeLoading: false,
  deleteAttributeSuccess: false,
  deleteAttributeError: false,
  // Delete Users
  deleteUsersLoading: false,
  deleteUsersSuccess: false,
  deleteUsersError: false,
};

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetCreateUserData: state => ({
      ...state,
      createUserLoading: false,
      createUserSuccess: false,
      createUserError: false,
    }),
    resetUsersListData: state => ({
      ...state,
      usersList: [],
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: false,
    }),
    resetAttributesListData: state => ({
      ...state,
      attributesList: null,
      getAttributesLoading: false,
      getAttributesSuccess: false,
      getAttributesError: false,
    }),
    resetAttributeMutationData: state => ({
      ...state,
      submitAttributeLoading: false,
      submitAttributeSuccess: false,
      submitAttributeError: false,
      deleteAttributeLoading: false,
      deleteAttributeSuccess: false,
      deleteAttributeError: false,
    }),
    resetDeleteUsersData: state => ({
      ...state,
      deleteUsersLoading: false,
      deleteUsersSuccess: false,
      deleteUsersError: false,
    }),
  },
  extraReducers: {
    // get user roles list
    [getUserAllRoles.pending]: state => ({
      ...state,
      usersList: null,
      getUsersLoading: true,
      getUsersSuccess: false,
      getUsersError: false,
    }),
    [getUserAllRoles.fulfilled]: (state, action) => {
      const { data: accountRoles } = action.payload ?? [];

      return {
        ...state,
        accountRoles,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false,
      };
    },
    [getUserAllRoles.rejected]: state => ({
      ...state,
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: true,
    }),
    // Users List
    [getUsersList.pending]: state => ({
      ...state,
      usersList: null,
      getUsersLoading: true,
      getUsersSuccess: false,
      getUsersError: false,
      usersListStatus: XHR_STATUS.pending,
    }),
    [getUsersList.fulfilled]: (state, action) => {
      const { data, userType, activeRoleDomain } = action.payload ?? {};
      const { data: usersList = [], meta: usersListMeta = [] } = data ?? null;
      const copyAccountRoles = cloneDeep(state.accountRoles);

      const checkedUserList = usersList.map((user) => {
        let isDisabled = false;
        user.roles.forEach((role) => {
          // Check for Account role users
          if (userType === UserLayer.Account) {
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }

          // Check for Domain role users
          if (userType === UserLayer.Domain) {
            if (activeRoleDomain.domain_id === role.domain_id) {
              if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
                isDisabled = true;
              }
            }
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }
        });
        return { ...user, isDisabled };
      });

      return {
        ...state,
        usersList: checkedUserList,
        usersListMeta,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false,
        usersListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getUsersList.rejected]: state => ({
      ...state,
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: true,
      usersListStatus: XHR_STATUS.rejected,
    }),
    // Create User
    [createUser.fulfilled]: state => ({
      ...state,
      createUserLoading: false,
      createUserSuccess: true,
      createUserError: false,
    }),
    [createUser.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: false,
        createUserError: message ?? 'Something went wrong while creating a user',
      };
    },
    // Attributes List
    [getAttributesList.pending]: state => ({
      ...state,
      attributesList: null,
      getAttributesLoading: true,
      getAttributesSuccess: false,
      getAttributesError: false,
    }),
    [getAttributesList.fulfilled]: (state, action) => {
      const { data: attributesList = [], meta: attributesListMeta = [] } = action.payload ?? {};

      return {
        ...state,
        attributesList,
        attributesListMeta,
        getAttributesLoading: false,
        getAttributesSuccess: true,
        getAttributesError: false,
      };
    },
    [getAttributesList.rejected]: state => ({
      ...state,
      getAttributesLoading: false,
      getAttributesSuccess: false,
      getAttributesError: true,
    }),
    [getOneAttributeData.fulfilled]: (state, action) => {
      const allAttributesList = cloneDeep(state.attributesList);
      const { data: attributeData = {} } = action.payload ?? {};
      const attributeIndex = allAttributesList?.findIndex(({ id }) => id === attributeData.id);

      if (allAttributesList && attributeIndex !== -1) {
        allAttributesList[attributeIndex] = { ...attributeData };
      }

      return {
        ...state,
        attributesList: allAttributesList,
      };
    },
    // Create Attribute
    [submitAttribute.pending]: state => ({
      ...state,
      submitAttributeLoading: true,
      submitAttributeSuccess: false,
      submitAttributeError: false,
    }),
    [submitAttribute.fulfilled]: state => ({
      ...state,
      submitAttributeLoading: false,
      submitAttributeSuccess: true,
      submitAttributeError: false,
    }),
    [submitAttribute.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        submitAttributeLoading: false,
        submitAttributeSuccess: false,
        submitAttributeError: message ?? 'Something went wrong while saving attribute data',
      };
    },
    // Delete Attribute
    [deleteAttribute.pending]: state => ({
      ...state,
      deleteAttributeLoading: true,
      deleteAttributeSuccess: false,
      deleteAttributeError: false,
    }),
    [deleteAttribute.fulfilled]: state => ({
      ...state,
      deleteAttributeLoading: false,
      deleteAttributeSuccess: true,
      deleteAttributeError: false,
    }),
    [deleteAttribute.rejected]: state => ({
      ...state,
      deleteAttributeLoading: false,
      deleteAttributeSuccess: false,
      deleteAttributeError: true,
    }),
    // Delete Users
    [deleteUsers.fulfilled]: state => ({
      ...state,
      deleteUsersLoading: false,
      deleteUsersSuccess: true,
      deleteUsersError: false,
    }),
    [deleteUsers.rejected]: (state, action) => {
      const { message = 'Something went wrong while deleting the selected users' } = action.payload ?? {};

      return {
        ...state,
        deleteUsersLoading: false,
        deleteUsersSuccess: false,
        deleteUsersError: message,
      };
    },
  },
});

export const {
  resetCreateUserData,
  resetUsersListData,
  resetAttributesListData,
  resetAttributeMutationData,
  resetDeleteUsersData,
} = admin.actions;

export default admin.reducer;
