export const ENDPOINTS = {
  template: '/images',
};

export const BE_FE_MAP = {
  getOne: {
    uuid: 'uuid',
    filename: 'filename',
    original_filename: 'originalFilename',
    filesize: 'filesize',
    scoped_type: 'scopedType',
    url: 'url',
    thumbnail_url: 'thumbnailUrl',
    thumbnail_filesize: 'thumbnailFilesize',
    created_at: 'createdAt',
    updated_at: 'updatedAt',
    deleted_at: 'deletedAt',
  },
};

export const FE_BE_MAP = {
  create: {
    file: 'name',
    type: 'description',
  },
};

