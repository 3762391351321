import firebase from 'firebase/app';

import 'firebase/messaging';
import { sendFCMToken } from './_api/notifications';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

let messaging = {
  onMessage: () => Promise,
  getToken: () => Promise,
};

try {
  messaging = firebase.messaging();
} catch (error) {}

export const getToken = async () => {
  try {
    const currentToken = await messaging.getToken({
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      await sendFCMToken(currentToken);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
