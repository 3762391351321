import get from 'lodash/get';

import { pickAndRename } from 'utils/objectUtils';

import { ENDPOINTS, BE_FE_MAP } from './constants';

class Image {
  constructor(restService) {
    this.restService = restService;
  }

  async create(form) {
    try {
      const { data } = await this.restService.post(
        `${ENDPOINTS.template}`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async delete(uuid) {
    try {
      const { data } = await this.restService.delete(
        `${ENDPOINTS.template}/${uuid}`,
      );
      const result = pickAndRename(data, BE_FE_MAP.getOne);

      return result;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async forceDelete(uuid) {}

  #handleError(error) {
    return Promise.reject({ code: get(error, 'response.status') });
  }
}

export default Image;
