import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { tokenRefreshErrorStatus } from 'constants/analytics';
import { optionsByDateValues } from 'layout/MainLayout/Analytics/constant';
import {
  getAnalyticsWidgetData,
  getToken,
  getOverviewChartData,
  getOverviewTableData,
  getTopAnalyticsData,
  getAnalyticsDeviceData,
  getAnalyticsOSData,
  getReferrersChartData,
  getTagsTableData,
  getTopLocationData,
  getMapData,
  getTopCitiesData,
  getTopCountriesData,
} from '_api/analytics';

const loadState = initState => JSON.parse(localStorage.getItem('analytics_filter'))?.analytics ?? initState;

const initialState = {
  dateRange: '7_days',
  dateRangeFilterValue: '',
  filterBy: 'and',
  token: '',
  filterOptions: [],
  startDate: optionsByDateValues['7_days'],
  endDate: optionsByDateValues.today,
  currentPage: 1,
  tableType: 'daily',
  cityTopType: 'engagement',
  countryTopType: 'engagement',
  locationType: 'top-country',
  widgetTotals: null,
  overviewChartData: null,
  overviewData: null,
  topAnalyticsData: null,
  analyticsOSData: null,
  analyticsDeviceData: null,
  tagsData: null,
  referrersData: null,
  locationTableData: null,
  mapData: null,
  cityTopData: null,
  countryTopData: null,
  isTokenExp: false,

  getOverviewTableDataLoading: false,
  getOverviewTableDataSuccess: false,
  getOverviewTableError: false,

  getAnalyticsOSDataLoading: true,
  getAnalyticsOSDataSuccess: false,
  getAnalyticsOSError: false,

  getAnalyticsDeviceDataLoading: true,
  getAnalyticsDeviceDataSuccess: false,
  getAnalyticsDeviceError: false,

  getTagsTableDataLoading: true,
  getTagsTableDataaSuccess: false,
  getTagsTableError: false,

  getReferrersChartDataLoading: true,
  getReferrersChartDataSuccess: false,
  getReferrersChartError: false,

  getTopLocationDataLoading: true,
  getTopLocationDataSuccess: false,
  getTopLocationError: false,

  getTopCitiesDataLoading: false,
  getTopCitiesDataSuccess: false,
  getTopCitiesError: false,

  getTopCountriesDataLoading: false,
  getTopCountriesDataSuccess: false,
  getTopCountriesError: false,

  getOverviewChartDataLoading: true,
  getOverviewChartDataSuccess: false,
  getOverviewChartError: false,

  getAnalyticsWidgetDataLoading: true,
  getAnalyticsWidgetDataSuccess: false,
  getAnalyticsWidgetError: false,

  getTokenLoading: true,
  getTokenSuccess: false,
  getTokenError: false,
};

export const analytics = createSlice({
  name: 'analytics',
  initialState: loadState(initialState),
  reducers: {
    setFilterValues: (state, action) => ({
      ...state,
      dateRange: action.payload.dateRange,
      dateRangeFilterValue: action.payload.dateRangeFilterValue,
      filterBy: action.payload.filterBy,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      filterOptions: [...action.payload.options],
    }),
    setOverviewTableType: (state, action) => ({
      ...state,
      tableType: action.payload,
    }),
    setOverviewTableCurrentPage: (state, action) => ({
      ...state,
      currentPage: action.payload,
    }),
    removeFilterItem: (state, action) => {
      const optionsList = cloneDeep(state.filterOptions);
      const filterOptionsRemoved = optionsList.filter(item => item.attributes !== action.payload);

      return {
        ...state,
        filterOptions: [...filterOptionsRemoved],
      };
    },
    resetFilterOptions: state => ({
      ...state,
      filterOptions: [],
      dateRange: '7_days',
      startDate: optionsByDateValues['7_days'],
      endDate: optionsByDateValues.today,
      dateRangeFilterValue: '',
      filterBy: 'and',
    }),
    resetOverviewChart: state => ({
      ...state,
      overviewChartData: [],
    }),
    setLocationType: (state, action) => ({
      ...state,
      locationType: action.payload,
    }),
    setCountryTopType: (state, action) => ({
      ...state,
      countryTopType: action.payload,
    }),
    setCityTopType: (state, action) => ({
      ...state,
      cityTopType: action.payload,
    }),
    resetToken: state => ({
      ...state,
      token: '',
    }),
  },
  extraReducers: {
    [getToken.pending]: state => ({
      ...state,
      getTokenLoading: true,
      getTokenSuccess: false,
      getTokenError: false,
    }),
    [getToken.fulfilled]: (state, action) => ({
      ...state,
      token: cloneDeep(action.payload.data).token,
      getTokenLoading: false,
      getTokenSuccess: true,
      getTokenError: false,
      isTokenExp: false,
    }),
    [getToken.rejected]: state => ({
      ...state,
      getTokenLoading: false,
      getTokenSuccess: false,
      getTokenError: true,
    }),
    [getAnalyticsWidgetData.pending]: state => ({
      ...state,
      getAnalyticsWidgetDataLoading: true,
      getAnalyticsWidgetDataSuccess: false,
      getAnalyticsWidgetError: false,
    }),
    [getAnalyticsWidgetData.fulfilled]: (state, action) => ({
      ...state,
      widgetTotals: cloneDeep(action.payload.data),
      getAnalyticsWidgetDataLoading: false,
      getAnalyticsWidgetDataSuccess: true,
      getAnalyticsWidgetError: false,
    }),
    [getAnalyticsWidgetData.rejected]: (state, error) => ({
      ...state,
      getAnalyticsWidgetDataLoading: false,
      getAnalyticsWidgetDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getAnalyticsWidgetError: true,
    }),
    [getOverviewChartData.pending]: state => ({
      ...state,
      getOverviewChartDataLoading: true,
      getOverviewChartDataSuccess: false,
      getOverviewChartError: false,
    }),
    [getOverviewChartData.fulfilled]: (state, action) => ({
      ...state,
      getOverviewChartDataLoading: false,
      getOverviewChartDataSuccess: true,
      getOverviewChartError: false,
      overviewChartData: cloneDeep(action.payload.data),
    }),
    [getOverviewChartData.rejected]: (state, error) => ({
      ...state,
      getOverviewChartDataLoading: false,
      getOverviewChartDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getOverviewChartError: true,
    }),
    [getOverviewTableData.pending]: state => ({
      ...state,
      getOverviewTableDataLoading: true,
      getOverviewTableDataSuccess: false,
      getOverviewTableError: false,
    }),
    [getOverviewTableData.fulfilled]: (state, action) => ({
      ...state,
      getOverviewTableDataLoading: false,
      getOverviewTableDataSuccess: true,
      getOverviewTableError: false,
      overviewData: cloneDeep(action.payload.data),
    }),
    [getOverviewTableData.rejected]: (state, error) => ({
      ...state,
      getOverviewTableDataLoading: false,
      getOverviewTableDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getOverviewTableError: true,
    }),

    [getTopAnalyticsData.pending]: state => ({
      ...state,
      getTopAnalyticsDataLoading: true,
      getTopAnalyticsDataSuccess: false,
      getTopAnalyticsError: false,
    }),
    [getTopAnalyticsData.fulfilled]: (state, action) => ({
      ...state,
      getTopAnalyticsDataLoading: false,
      getTopAnalyticsDataSuccess: true,
      getTopAnalyticsError: false,
      topAnalyticsData: cloneDeep(action.payload.data),
    }),
    [getTopAnalyticsData.rejected]: (state, error) => ({
      ...state,
      getTopAnalyticsDataLoading: false,
      getTopAnalyticsDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getTopAnalyticsError: true,
    }),

    [getAnalyticsDeviceData.pending]: state => ({
      ...state,
      getAnalyticsDeviceDataLoading: true,
      getAnalyticsDeviceDataSuccess: false,
      getAnalyticsDeviceError: false,
    }),
    [getAnalyticsDeviceData.fulfilled]: (state, action) => ({
      ...state,
      getAnalyticsDeviceDataLoading: false,
      getAnalyticsDeviceDataSuccess: true,
      getAnalyticsDeviceError: false,
      analyticsDeviceData: cloneDeep(action.payload.data),
    }),
    [getAnalyticsDeviceData.rejected]: (state, error) => ({
      ...state,
      getAnalyticsDeviceDataLoading: false,
      getAnalyticsDeviceDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getAnalyticsDeviceError: true,
    }),

    [getAnalyticsOSData.pending]: state => ({
      ...state,
      getAnalyticsOSDataLoading: true,
      getAnalyticsOSDataSuccess: false,
      getAnalyticsOSError: false,
    }),
    [getAnalyticsOSData.fulfilled]: (state, action) => ({
      ...state,
      getAnalyticsOSDataLoading: false,
      getAnalyticsOSDataSuccess: true,
      getAnalyticsOSError: false,
      analyticsOSData: cloneDeep(action.payload.data),
    }),
    [getAnalyticsOSData.rejected]: (state, error) => ({
      ...state,
      getAnalyticsOSDataLoading: false,
      getAnalyticsOSDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getAnalyticsOSError: true,
    }),

    [getReferrersChartData.pending]: state => ({
      ...state,
      getReferrersChartDataLoading: true,
      getReferrersChartDataSuccess: false,
      getReferrersChartError: false,
    }),
    [getReferrersChartData.fulfilled]: (state, action) => ({
      ...state,
      getReferrersChartDataLoading: false,
      getReferrersChartDataSuccess: true,
      getReferrersChartError: false,
      referrersData: cloneDeep(action.payload.data),
    }),
    [getReferrersChartData.rejected]: (state, error) => ({
      ...state,
      getReferrersChartDataLoading: false,
      getReferrersChartDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getReferrersChartError: true,
    }),

    [getTagsTableData.pending]: state => ({
      ...state,
      getTagsTableDataLoading: true,
      getTagsTableDataSuccess: false,
      getTagsTableError: false,
    }),
    [getTagsTableData.fulfilled]: (state, action) => ({
      ...state,
      getTagsTableDataLoading: false,
      getTagsTableDataaSuccess: true,
      getTagsTableError: false,
      tagsData: cloneDeep(action.payload.data),
    }),
    [getTagsTableData.rejected]: (state, error) => ({
      ...state,
      getTagsTableDataLoading: false,
      getTagsTableDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getTagsTableError: true,
    }),

    [getTopLocationData.pending]: state => ({
      ...state,
      getTopLocationDataLoading: true,
      getTopLocationDataSuccess: false,
      getTopLocationError: false,
    }),
    [getTopLocationData.fulfilled]: (state, action) => ({
      ...state,
      getTopLocationDataLoading: false,
      getTopLocationDataSuccess: true,
      getTopLocationError: false,
      locationTableData: cloneDeep(action.payload.data),
    }),
    [getTopLocationData.rejected]: (state, error) => ({
      ...state,
      getTopLocationDataLoading: false,
      getTopLocationDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getTopLocationError: true,
    }),

    [getMapData.pending]: state => ({
      ...state,
      getMapDataLoading: true,
      getMapDataSuccess: false,
      getMapError: false,
    }),
    [getMapData.fulfilled]: (state, action) => ({
      ...state,
      getMapDataLoading: false,
      getMapDataSuccess: true,
      getMapError: false,
      mapData: cloneDeep(action.payload.data),
    }),
    [getMapData.rejected]: (state, error) => ({
      ...state,
      getMapDataLoading: false,
      getMapDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getMapError: true,
    }),

    [getTopCitiesData.pending]: state => ({
      ...state,
      getTopCitiesDataLoading: true,
      getTopCitiesDataSuccess: false,
      getTopCitiesError: false,
    }),
    [getTopCitiesData.fulfilled]: (state, action) => ({
      ...state,
      getTopCitiesDataLoading: false,
      getTopCitiesDataSuccess: true,
      getTopCitiesError: false,
      cityTopData: cloneDeep(action.payload.data),
    }),
    [getTopCitiesData.rejected]: (state, error) => ({
      ...state,
      getTopCitiesDataLoading: false,
      getTopCitiesDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getTopCitiesError: true,
    }),

    [getTopCountriesData.pending]: state => ({
      ...state,
      getTopCountriesDataLoading: true,
      getTopCountriesDataSuccess: false,
      getTopCountriesError: false,
    }),
    [getTopCountriesData.fulfilled]: (state, action) => ({
      ...state,
      getTopCountriesDataLoading: false,
      getTopCountriesDataSuccess: true,
      getTopCountriesError: false,
      countryTopData: cloneDeep(action.payload.data),
    }),
    [getTopCountriesData.rejected]: (state, error) => ({
      ...state,
      getTopCountriesDataLoading: false,
      getTopCountriesDataSuccess: false,
      isTokenExp: error.payload.status === tokenRefreshErrorStatus,
      getTopCountriesError: true,
    }),
  },
});

export const {
  setFilterValues,
  removeFilterItem,
  resetOverviewChart,
  resetFilterOptions,
  setOverviewTableCurrentPage,
  setOverviewTableType,
  setLocationType,
  setCountryTopType,
  setCityTopType,
} = analytics.actions;

export default analytics.reducer;
