import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getUserAllRoles = createAsyncThunk('admin/getUserAllRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/roles');

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUsersList = createAsyncThunk(
  'admin/getUsersList',
  async ({ userRoleType }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { role: activeRoleDomain },
      } = getState();

      const response = await axiosInstance.get(`/users`, {
        params: {
          per_page: 500,
        },
      });
      return { data: response.data, userType: userRoleType, activeRoleDomain };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createUser = createAsyncThunk('admin/createUser', async ({ userData = {} }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/users', userData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUsers = createAsyncThunk(
  'admin/deleteUsers',
  async ({ data = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/delete`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
