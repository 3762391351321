// third-party
import { createSlice } from '@reduxjs/toolkit';
import { deleteNotification, getNotificationsList, readAllNotifications } from '_api/notifications';
import { cloneDeep } from 'lodash';

// initial state
const initialState = {
  notificationsList: [],
};

// ==============================|| SLICE - MENU ||============================== //

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      const { notification } = action.payload;
      return {
        ...state,
        notificationsList: [notification, ...state.notificationsList],
      };
    },
    removeNotificationByID: (state, action) => {
      const { notificationID: removableID } = action.payload;
      const notificationsList = state.notificationsList.filter(({ notificationID }) => notificationID !== removableID);
      return {
        ...state,
        notificationsList,
      };
    },
    editNotification: (state, action) => {
      const { notification_uuid, notificationItem } = action.payload;
      const notificationsList = cloneDeep(state.notificationsList);
      const notItm = notificationsList.find(not => not.uuid === notification_uuid);

      if (notItm) {
        notItm.data = { progress: notificationItem.progress };
      }

      return {
        ...state,
        notificationsList,
      };
    },
    readNotification: (state, action) => {
      const { notificationID: readID } = action.payload;
      const notificationsList = [...state.notificationsList];
      const matchedNotificationID = state.notificationsList.findIndex(
        ({ notificationID }) => notificationID === readID,
      );
      notificationsList[matchedNotificationID] = {
        ...notificationsList[matchedNotificationID],
        is_read: true,
      };
      return {
        ...state,
        notificationsList,
      };
    },
  },
  extraReducers: {
    [readAllNotifications.pending]: (state) => {
      const notificationsList = state.notificationsList.map(notification => ({
        ...notification,
        is_read: true,
      }));
      return {
        ...state,
        notificationsList,
      };
    },
    [getNotificationsList.fulfilled]: (state, action) => {
      const { data: notificationsList } = action.payload;
      return {
        ...state,
        notificationsList,
      };
    },
    [deleteNotification.fulfilled]: (state, action) => {
      const { notificationID } = action.payload;
      const notificationsList = state.notificationsList.filter(({ uuid }) => uuid !== notificationID);

      return {
        ...state,
        notificationsList,
      };
    },
  },
});

export default notifications.reducer;

export const { addNotification, editNotification, removeNotificationByID, readNotification } = notifications.actions;
