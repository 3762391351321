import { createSlice } from '@reduxjs/toolkit';
import { getMyDomainsList, updateDomainInformation } from '_api/account';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  domainsList: [],
  requestDomainsStatus: XHR_STATUS.idle,
  getMyDomainsLoading: false,
  getMyDomainsSuccess: false,
  getMyDomainsError: false,
};

const domains = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    resetDomainsListData: state => ({
      ...state,
      domainsList: [],
      getMyDomainsLoading: false,
      getMyDomainsSuccess: false,
      getMyDomainsError: false,
      domainRequestStatus: XHR_STATUS.idle,
    }),
  },
  extraReducers: {
    [getMyDomainsList.pending]: state => ({
      ...state,
      getMyDomainsLoading: true,
      getMyDomainsSuccess: false,
      getMyDomainsError: false,
      domainRequestStatus: XHR_STATUS.pending,
    }),
    [getMyDomainsList.fulfilled]: (state, action) => {
      const { data: domainsList = [] } = action.payload ?? {};

      return {
        ...state,
        domainsList,
        getMyDomainsLoading: false,
        getMyDomainsSuccess: true,
        getMyDomainsError: false,
        domainRequestStatus: XHR_STATUS.fulfilled,
      };
    },
    [getMyDomainsList.rejected]: state => ({
      ...state,
      getMyDomainsLoading: false,
      getMyDomainsSuccess: false,
      getMyDomainsError: true,
      domainRequestStatus: XHR_STATUS.rejected,
    }),
    [updateDomainInformation.fulfilled]: (state, action) => {
      const domainsList = [...state.domainsList];
      const { data: { uuid: domainID = '', settings: { redirect_type = '' }, qr_templates = [] } = {} } =
        action.payload ?? {};

      if (domainID) {
        const matchedDomainID = domainsList.findIndex(({ uuid }) => uuid === domainID);

        if (matchedDomainID !== -1 && redirect_type) {
          domainsList[matchedDomainID] = {
            ...(domainsList[matchedDomainID] ?? {}),
            redirect_type,
          };
        }

        if (matchedDomainID !== -1 && qr_templates?.length) {
          domainsList[matchedDomainID] = {
            ...(domainsList[matchedDomainID] ?? {}),
            qr_templates,
          };
        }
      }

      return {
        ...state,
        domainsList,
      };
    },
  },
});

export const { resetDomainsListData } = domains.actions;

export default domains.reducer;
