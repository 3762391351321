// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function CardContent() {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
        }),
      },
    },
  };
}
