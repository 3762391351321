import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePermissionRedirect from 'hooks/usePermissionRedirect';

const RedirectHandler = ({ children = null }) => {
  const { redirect } = usePermissionRedirect();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
  }, [redirect, navigate]);

  return <>{children}</>;
};

export default RedirectHandler;
