import { createSlice } from '@reduxjs/toolkit';
import {
  createQRTemplate,
  editQRTemplate,
  getQRTemplateByID,
  getQRTemplatesList,
  removeQRTemplate,
} from '_api/admin/qr-templates';

const initialState = {
  // Get linkQRTemplates
  QRTemplatesList: [],
  QRTemplatesListMeta: {},
  currentQRTemplateData: {},
  getQRTemplatesLoading: false,
  getQRTemplatesSuccess: false,
  getQRTemplatesError: false,
  // Create QRQRTemplate
  createQRTemplate: {
    createQRTemplateLoading: false,
    createQRTemplateSuccess: false,
    createQRTemplateError: false,
  },
  // Edit QRQRTemplate
  editQRTemplate: {
    editQRTemplateLoading: false,
    editQRTemplateSuccess: false,
    editQRTemplateError: false,
  },
  // Remove QRQRTemplate
  removeQRTemplate: {
    removeQRTemplateLoading: false,
    removeQRTemplateSuccess: false,
    removeQRTemplateError: false,
  },
};

const QRTemplates = createSlice({
  name: 'qrTemplates',
  initialState,
  reducers: {
    resetCreateQRTemplateData: state => ({
      ...state,
      createQRTemplate: {
        createQRTemplateLoading: false,
        createQRTemplateSuccess: false,
        createQRTemplateError: false,
      },
    }),
    resetEditQRTemplateData: state => ({
      ...state,
      editQRTemplate: {
        editQRTemplateLoading: false,
        editQRTemplateSuccess: false,
        editQRTemplateError: false,
      },
    }),
    resetDeleteQRTemplateData: state => ({
      ...state,
      removeQRTemplate: {
        removeQRTemplateLoading: false,
        removeQRTemplateSuccess: false,
        removeQRTemplateError: false,
      },
    }),
    resetQRTemplatesListData: state => ({
      ...state,
      QRTemplatesList: [],
      QRTemplatesListMeta: {},
      getQRTemplatesLoading: false,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: false,
    }),
    resetCurrentQRTemplateData: state => ({
      ...state,
      currentQRTemplateData: {},
      getQRTemplatesLoading: false,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: false,
    }),
  },
  extraReducers: {
    // Get QR Templates
    [getQRTemplatesList.pending]: state => ({
      ...state,
      QRTemplatesListMeta: {},
      getQRTemplatesLoading: true,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: false,
    }),
    [getQRTemplatesList.fulfilled]: (state, action) => {
      const { data: QRTemplatesList = null, meta } = action.payload ?? {};

      return {
        ...state,
        QRTemplatesList,
        QRTemplatesListMeta: meta,
        getQRTemplatesLoading: false,
        getQRTemplatesSuccess: true,
        getQRTemplatesError: false,
      };
    },
    [getQRTemplatesList.rejected]: state => ({
      ...state,
      getQRTemplatesLoading: false,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: true,
    }),
    // Get QR Template By ID
    [getQRTemplateByID.pending]: state => ({
      ...state,
      getQRTemplatesLoading: true,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: false,
    }),
    [getQRTemplateByID.fulfilled]: (state, action) => {
      const { data: currentQRTemplateData = null } = action.payload ?? {};

      return {
        ...state,
        currentQRTemplateData,
        getQRTemplatesLoading: false,
        getQRTemplatesSuccess: true,
        getQRTemplatesError: false,
      };
    },
    [getQRTemplateByID.rejected]: state => ({
      ...state,
      getQRTemplatesLoading: false,
      getQRTemplatesSuccess: false,
      getQRTemplatesError: true,
    }),
    // Create QR Template
    [createQRTemplate.pending]: state => ({
      ...state,
      createQRTemplate: {
        createQRTemplateLoading: true,
        createQRTemplateSuccess: false,
        createQRTemplateError: false,
      },
    }),
    [createQRTemplate.fulfilled]: (state, action) => {
      const { data: newQRTemplateData = {}, meta: QRTemplatesListMeta } = action.payload ?? {};

      return {
        ...state,
        QRTemplatesList: [...(state.QRTemplatesList), newQRTemplateData],
        QRTemplatesListMeta,
        createQRTemplate: {
          createQRTemplateLoading: false,
          createQRTemplateSuccess: true,
          createQRTemplateError: false,
        },
      };
    },
    [createQRTemplate.rejected]: state => ({
      ...state,
      createQRTemplate: {
        createQRTemplateLoading: false,
        createQRTemplateSuccess: false,
        createQRTemplateError: true,
      },
    }),
    // edit QRQRTemplate
    [editQRTemplate.pending]: state => ({
      ...state,
      editQRTemplate: {
        editQRTemplateLoading: true,
        editQRTemplateSuccess: false,
        editQRTemplateError: false,
      },
    }),
    [editQRTemplate.fulfilled]: state => ({
      ...state,
      editQRTemplate: {
        editQRTemplateLoading: false,
        editQRTemplateSuccess: true,
        editQRTemplateError: false,
      },
    }),
    [editQRTemplate.rejected]: state => ({
      ...state,
      editQRTemplate: {
        editQRTemplateLoading: false,
        editQRTemplateSuccess: false,
        editQRTemplateError: true,
      },
    }),
    // remove QRQRTemplate
    [removeQRTemplate.pending]: state => ({
      ...state,
      removeQRTemplate: {
        removeQRTemplateLoading: true,
        removeQRTemplateSuccess: false,
        removeQRTemplateError: false,
      },
    }),
    [removeQRTemplate.fulfilled]: state => ({
      ...state,
      removeQRTemplate: {
        removeQRTemplateLoading: false,
        removeQRTemplateSuccess: true,
        removeQRTemplateError: false,
      },
    }),
    [removeQRTemplate.rejected]: state => ({
      ...state,
      removeQRTemplate: {
        removeQRTemplateLoading: false,
        removeQRTemplateSuccess: false,
        removeQRTemplateError: true,
      },
    }),
  },
});

export const {
  resetCurrentQRTemplateData,
  resetCreateQRTemplateData,
  resetEditQRTemplateData,
  resetDeleteQRTemplateData,
} = QRTemplates.actions;

export default QRTemplates.reducer;
