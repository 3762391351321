// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import auth from './auth';
import links from './links';
import templates from './link-templates';
import qrTemplates from './qr-templates';
import domains from './domains';
import admin from './admin';
import tenant from './tenant';
import notifications from './notifications';
import dashboard from './dashboard';
import filters from './filters';
import tableViews from './tableViews';
import tags from './tags';
import feature from './feature';
import analytics from './analytics';
import experimentalFeatures from './experimental-features';
import ipExclusions from './ip-exclusions';
import trustedDestinations from './trusted-destinations';
import pendingLinks from './pending-links';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  auth,
  menu,
  snackbar,
  links,
  templates,
  qrTemplates,
  ipExclusions,
  domains,
  admin,
  tenant,
  notifications,
  dashboard,
  filters,
  tableViews,
  tags,
  feature,
  analytics,
  experimentalFeatures,
  trustedDestinations,
  pendingLinks,
});

export default reducers;
