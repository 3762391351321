import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import Logo from 'components/logo';
import LogoIcon from 'components/icon';

import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

const SidebarDrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {open ? (
        <Logo sx={{ width: '200px', height: 35, pr: 4 }} />
      ) : (
        <LogoIcon sx={{ width: 60, height: 35, pr: 2, pl: 2 }} />
      )}
    </DrawerHeaderStyled>
  );
};

SidebarDrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default SidebarDrawerHeader;
