import { createContext, useEffect } from 'react';
import {
  featureSelector,
  hasObservePointFeatureSelector,
  hasTrustedDestinationSelector,
  hasPendingLinksSelector,
  hasELinksSelector,
} from 'store/selectors/feature';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { getFeatures } from '_api/features';

const defaultState = {
  features: [],
  loading: true,
  error: null,
};

const FeatureContext = createContext(defaultState);

const FeatureProvider = ({ children }) => {
  const { features, getFeaturesLoading } = useSelector(featureSelector);
  const { accessToken = '', domainID = '' } = useSelector(state => state.auth || {});
  const hasObservePointFeature = useSelector(hasObservePointFeatureSelector);
  const hasTrustedDestinationFeature = useSelector(hasTrustedDestinationSelector);
  const hasPendingLinksFeature = useSelector(hasPendingLinksSelector);
  const hasELinksFeature = useSelector(hasELinksSelector);

  useEffect(() => {
    if (!features && accessToken && domainID) {
      dispatch(getFeatures());
    }
  }, [features, accessToken, domainID]);

  return (
    <FeatureContext.Provider
      value={{
        features,
        loading: getFeaturesLoading,
        hasObservePointFeature,
        hasPendingLinksFeature,
        hasTrustedDestinationFeature,
        hasELinksFeature,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export { FeatureContext, FeatureProvider };
