import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';

export const getTagsList = createAsyncThunk(
  'tags/getTagsList',
  async (
    {
      currentPage = 0,
      currentRowCount = 25,
      currentSearchValue = '',
      currentFilters = [],
      currentFilterLink = 'and',
      currentSortData: { field: sort = 'created_at', sort: sort_direction = 'desc' } = {},
      isLazyLoad = false,
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();

      const queryParams = {
        page: Number(currentPage),
        per_page: Number(currentRowCount),
        search: currentSearchValue,
        filters: currentFilters,
        filter_link: currentFilterLink,
        sort,
        sort_direction,
      };

      const response = await axiosInstance.get(`/${domainID}/tags`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });

      return { ...response.data, isLazyLoad };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getFullTagsList = createAsyncThunk('tags/getTagsList', async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
    } = getState();

    const response = await axiosInstance.get(`/${domainID}/tags/list`);

    return { ...response.data };
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const submitTag = createAsyncThunk(
  'admin/submitTag',
  async ({ tagData = {}, isEditMode = false }, { rejectWithValue, getState }) => {
    try {
      let response;
      const {
        auth: { domainID = '' },
      } = getState();
      if (isEditMode) {
        const tagID = tagData.uuid ?? '';
        response = await axiosInstance.put(`/${domainID}/tags/${tagID}`, tagData);
      } else {
        response = await axiosInstance.post(`/${domainID}/tags`, tagData);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTag = createAsyncThunk('admin/deleteTag', async ({ tagID = '' }, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
    } = getState();
    const response = await axiosInstance.delete(`/${domainID}/tags/${tagID}`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
