import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { UserOutlined, SyncOutlined, LogoutOutlined, CheckOutlined } from '@ant-design/icons';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import sortBy from 'lodash/sortBy';
import noop from 'lodash/noop';

import { tenantListSelector } from 'selectors/tenant';
import { selectIsLogoutPending } from 'store/reducers/auth';
import { resetActiveItem } from 'store/reducers/menu';
import { dispatch } from 'store';

const ProfileTab = ({ handleLogout, handleClosePopup }) => {
  const navigate = useNavigate();

  const [isCompaniesOpened, setIsCompaniesOpened] = useState(false);
  const tenantsList = useSelector(tenantListSelector);
  const isLogoutPending = useSelector(selectIsLogoutPending);

  const orderedTenantsList = useMemo(
    () => {
      const sortedList = sortBy(tenantsList, ['name']);

      const selectedItemIndex = sortedList.findIndex(({ frontend_url }) => window.location.origin === frontend_url);

      if (selectedItemIndex !== -1) {
        const [selectedItem] = sortedList.splice(selectedItemIndex, 1);
        sortedList.unshift(selectedItem);
      }

      return sortedList;
    },
    [tenantsList],
  );

  const toggleCompaniesDropdown = () => {
    setIsCompaniesOpened(!isCompaniesOpened);
  };

  const handleListItemClick = (callback = noop) => () => {
    callback();
    dispatch(resetActiveItem());
    handleClosePopup();
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        '& .MuiListItemIcon-root': { minWidth: 32 },
        '& .MuiListItemButton-gutters': { boxShadow: 'inset 0px 1px 1px #f0f0f0' },
      }}
    >
      <ListItemButton
        onClick={handleListItemClick(() => navigate('/account', { replace: true }))}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="My Account" />
      </ListItemButton>

      {orderedTenantsList?.length >= 2 && (
        <ListItemButton onClick={toggleCompaniesDropdown}>
          <ListItemIcon>
            <SyncOutlined style={{ color: '#8C8C8C' }} />
          </ListItemIcon>
          <ListItemText primary="Switch Companies" />
          {isCompaniesOpened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse
        unmountOnExit
        in={isCompaniesOpened}
        timeout="auto"
        sx={{
          '& .MuiListItemButton-gutters': { boxShadow: 'none', width: '100%' },
          '& .MuiTypography-root': { fontSize: '14px' },
        }}
      >
        <List component="div" disablePadding style={{ maxHeight: '55vh', overflow: 'auto' }}>
          {orderedTenantsList.map(({ id = '', name = '', frontend_url: destination = '' }) => {
            const isChecked = destination === window.location.origin;
            const textColor = isChecked ? { mr: 2, flex: '0 1 auto', span: { color: '#19B08A' } } : null;

            return (
              <ListItemButton
                key={id}
                onClick={handleListItemClick(() => window.location.replace(destination))}>
                <ListItemText primary={name} sx={textColor} />
                {isChecked && <CheckOutlined style={{ color: '#19B08A' }} />}
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>

      <ListItemButton
        disabled={isLogoutPending}
        onClick={handleLogout}
      >
        <ListItemIcon>
          <LogoutOutlined style={{ color: '#8C8C8C' }} />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
