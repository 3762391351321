// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
  return {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState: { clamp } }) => ({
          ...(clamp && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: String(clamp),
            WebkitBoxOrient: 'vertical',
          })
        }),
        gutterBottom: {
          marginBottom: 12
        }
      }
    }
  };
}
