import { createSlice } from '@reduxjs/toolkit';
import {
  deleteProfilePicture,
  getMyProfileData,
  getMyUserData,
  getUserDomainInformation,
  updateProfileData,
  updateProfilePassword,
  updateProfilePicture,
  logOut,
} from '_api/account';
import { XHR_STATUS } from 'constants/xhr-status';
import cloneDeep from 'lodash/cloneDeep';
import { UserLayer, UserRoles } from 'pages/admin/constant';

export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  accessToken: null,
  domainID: '',
  userRoleDomainID: '',
  isLoginFailed: false,
  user: {},
  userProfile: {},
  domainInfoList: [],
  accountInfoLoading: false,
  accountInfoSuccess: false,
  accountInfoError: false,
  domainInfoLoading: false,
  errorMessageChangePassword: '',
  isStatusChangePassword: false,
  role: null,
  roles: [],
  permissions: [],
  userRoleType: '',
  isUpdateProfilePasswordPending: false,
  isLogoutPending: false,
  authUserStatus: XHR_STATUS.idle,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { userData = null, domainID = '', userRoleDomainID } = action.payload || {};
      if (!userData) {
        return state;
      }
      return {
        ...state,
        user: userData,
        domainID,
        userRoleDomainID,
        isLoggedIn: true,
      };
    },
    setUserDomain: (state, action) => {
      const { domainID = '', userRoleDomainID, currActiveDomainRole } = action.payload || {};

      if (!domainID) {
        return state;
      }
      return {
        ...state,
        domainID,
        userRoleDomainID,
        role: currActiveDomainRole,
      };
    },
    setAccessToken: (state, action) => {
      const { accessToken = '' } = action.payload || {};
      return {
        ...state,
        isInitialized: true,
        accessToken,
      };
    },
    setRefreshToken: (state, action) => {
      const { refresh_token = '' } = action.payload || {};
      return {
        ...state,
        userProfile: {
          ...(state.userProfile ?? {}),
          refresh_token,
        },
      };
    },
    resetErrorMessage: state => ({
      ...state,
      errorMessageChangePassword: '',
      isStatusChangePassword: false,
    }),
    resetUserData: () => ({ ...initialState }),
  },
  extraReducers: {
    [getMyUserData.pending]: (state) => {
      state.authUserStatus = XHR_STATUS.pending;
    },
    [getMyUserData.fulfilled]: (state, action) => {
      const { data: userData } = action.payload;
      const currPermissions = cloneDeep(userData.permissions);
      const copyRoles = cloneDeep(userData.roles);

      const checkUserRoleType = copyRoles.some(role => role.layer === UserLayer.Account)
        ? UserLayer.Account
        : UserLayer.Domain;

      let activeRoleWithoutDomain = copyRoles.find(role => role.code === UserRoles.BillingManager);

      // search for account_admin
      if (!activeRoleWithoutDomain) {
        activeRoleWithoutDomain = copyRoles.find(role => role.code === UserRoles.AccountAdmin);
      }

      let activeRoleWthDomain = copyRoles.find(role => role.domain_id === Number(state.userRoleDomainID));

      const domainListAccess =
        checkUserRoleType === UserLayer.Account
          ? userData.domains
          : userData.domains.filter(domain => userData.roles.some(role => role.domain_id === domain.id));

      // default domain logic
      if (!activeRoleWthDomain) {
        [activeRoleWthDomain] = userData.roles;
      }

      return {
        ...state,
        user: {
          ...userData,
          domains: domainListAccess,
        },
        permissions: currPermissions,
        role: activeRoleWithoutDomain ?? activeRoleWthDomain,
        domainID: activeRoleWthDomain.domain_uuid,
        roles: userData.roles,
        isLoggedIn: true,
        userRoleType: checkUserRoleType,
        authUserStatus: XHR_STATUS.fulfilled,
      };
    },
    [getMyUserData.rejected]: () => ({
      isLoginFailed: true,
      authUserStatus: XHR_STATUS.rejected,
    }),
    [getUserDomainInformation.pending]: state => ({
      ...state,
      domainInfoLoading: true,
    }),
    [getUserDomainInformation.fulfilled]: (state, action) => {
      const { data: domainList } = action.payload;
      return {
        ...state,
        domainInfoList: cloneDeep(domainList),
        domainInfoLoading: false,
      };
    },
    [getUserDomainInformation.rejected]: state => ({
      ...state,
      domainInfoLoading: false,
    }),
    [getMyProfileData.pending]: state => ({
      ...state,
      accountInfoLoading: true,
    }),
    [getMyProfileData.fulfilled]: (state, action) => {
      const { data: userProfileData } = action.payload;
      return {
        ...state,
        userProfile: cloneDeep(userProfileData),
        accountInfoLoading: false,
      };
    },
    [getMyProfileData.rejected]: state => ({
      ...state,
      accountInfoLoading: false,
    }),

    // Update Profile Picture
    [updateProfilePicture.pending]: state => ({
      ...state,
      accountInfoLoading: true,
    }),
    [updateProfilePicture.fulfilled]: (state, action) => {
      const { data: userNewProfileData } = action.payload;

      return {
        ...state,
        userProfile: userNewProfileData,
        accountInfoLoading: false,
      };
    },
    [updateProfilePicture.rejected]: state => ({
      ...state,
      accountInfoLoading: false,
    }),

    // Delete Profile Picture
    [deleteProfilePicture.pending]: state => ({
      ...state,
      accountInfoLoading: true,
    }),
    [deleteProfilePicture.fulfilled]: (state, action) => {
      const { data: userNewProfileData } = action.payload;

      return {
        ...state,
        userProfile: userNewProfileData,
        accountInfoLoading: false,
      };
    },
    [deleteProfilePicture.rejected]: state => ({
      ...state,
      accountInfoLoading: false,
    }),

    // Update Profile Data
    [updateProfileData.pending]: state => ({
      ...state,
      accountInfoLoading: true,
      accountInfoSuccess: false,
      accountInfoError: false,
    }),
    [updateProfileData.fulfilled]: (state, action) => {
      const { data: userNewProfileData } = action.payload;

      return {
        ...state,
        userProfile: cloneDeep(userNewProfileData),
        accountInfoLoading: false,
        accountInfoSuccess: true,
        accountInfoError: false,
      };
    },
    [updateProfileData.rejected]: state => ({
      ...state,
      accountInfoLoading: false,
      accountInfoSuccess: false,
      accountInfoError: 'Something went wrong while updating personal profile',
    }),
    [updateProfilePassword.pending]: state => ({
      ...state,
      isUpdateProfilePasswordPending: true,
    }),
    [updateProfilePassword.fulfilled]: state => ({
      ...state,
      isUpdateProfilePasswordPending: false,
    }),
    [updateProfilePassword.rejected]: state => ({
      ...state,
      errorMessageChangePassword: 'Something went wrong while updating personal profile',
      isStatusChangePassword: 'Success',
      isUpdateProfilePasswordPending: false,
    }),
    [logOut.pending]: state => ({
      ...state,
      isLogoutPending: true,
    }),
    [logOut.fulfilled]: () => ({ ...initialState }),
    [logOut.rejected]: state => ({
      ...state,
      isLogoutPending: false,
    }),
  },
});

export const selectIsUpdateProfilePasswordPending = state => state.auth.isUpdateProfilePasswordPending;
export const selectIsLogoutPending = state => state.auth.isLogoutPending;

export const {
  logOutUser,
  resetErrorMessage,
  setUserData,
  resetUserData,
  setAccessToken,
  setRefreshToken,
  setUserDomain,
} = auth.actions;

export default auth.reducer;
