import { createSlice } from '@reduxjs/toolkit';
import { getTagsList, submitTag, deleteTag } from '_api/admin/tags';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  tagsList: null,
  tagsListStatus: XHR_STATUS.idle,
  tagsListMeta: {},
  getTagsListLoading: false,
  getTagsListSuccess: false,
  getTagsListError: false,
  submitTag: {
    submitTagLoading: false,
    submitTagSuccess: false,
    submitTagError: false,
  },
  deleteTag: {
    deleteTagLoading: false,
    deleteTagSuccess: false,
    deleteTagError: false,
  },
};

const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    resetTagsListData: () => ({
      ...initialState,
    }),
    resetSubmitTagData: state => ({
      ...state,
      submitTag: initialState.submitTag,
    }),
    resetDeleteTagData: state => ({
      ...state,
      deleteTag: initialState.deleteTag,
    }),
  },
  extraReducers: {
    [getTagsList.pending]: state => ({
      ...state,
      getTagsListLoading: true,
      getTagsListSuccess: false,
      getTagsListError: false,
      tagsListStatus: XHR_STATUS.pending,
    }),
    [getTagsList.fulfilled]: (state, action) => {
      const { data: tagsList = [], meta: tagsListMeta = {}, isLazyLoad = false } = action.payload ?? {};

      return {
        ...state,
        tagsList: isLazyLoad ? [...(state.tagsList ?? []), ...tagsList] : tagsList,
        tagsListMeta,
        getTagsListLoading: false,
        getTagsListSuccess: true,
        getTagsListError: false,
        tagsListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getTagsList.rejected]: state => ({
      ...state,
      getTagsListLoading: false,
      getTagsListSuccess: false,
      getTagsListError: true,
      tagsListStatus: XHR_STATUS.rejected,
    }),

    //  Submit Tag
    [submitTag.pending]: state => ({
      ...state,
      submitTag: {
        submitTagLoading: true,
        submitTagSuccess: false,
        submitTagError: false,
      },
    }),
    [submitTag.fulfilled]: state => ({
      ...state,
      submitTag: {
        submitTagLoading: false,
        submitTagSuccess: true,
        submitTagError: false,
      },
    }),
    [submitTag.rejected]: state => ({
      ...state,
      submitTag: {
        submitTagLoading: false,
        submitTagSuccess: false,
        submitTagError: true,
      },
    }),

    //  Detete Tag
    [deleteTag.pending]: state => ({
      ...state,
      deleteTag: {
        deleteTagLoading: true,
        deleteTagSuccess: false,
        deleteTagError: false,
      },
    }),
    [deleteTag.fulfilled]: state => ({
      ...state,
      deleteTag: {
        deleteTagLoading: false,
        deleteTagSuccess: true,
        deleteTagError: false,
      },
    }),
    [deleteTag.rejected]: state => ({
      ...state,
      deleteTag: {
        deleteTagLoading: false,
        deleteTagSuccess: false,
        deleteTagError: true,
      },
    }),
  },
});

export default tags.reducer;

export const { resetTagsListData, resetSubmitTagData, resetDeleteTagData } = tags.actions;
