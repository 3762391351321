import { lazy } from 'react';
import CommonLayout from 'layout/CommonLayout';
import loadable from 'components/Loadable';
import Auth0Logout from 'pages/auth/auth0Logout';

const MaintenanceError403 = loadable(lazy(() => import('pages/maintenance/403')));
const MaintenanceError404 = loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = loadable(lazy(() => import('pages/maintenance/coming-soon')));

const CommonRoutes = {
  path: '/',  
  element: <CommonLayout />,
  children: [
    {
      path: 'maintenance',
    },
    {
      path: 'logout',
      element: <Auth0Logout />,
    },
    {
      path: '403',
      element: <MaintenanceError403 />,
    },
    {
      path: '404',
      element: <MaintenanceError404 />,
    },
    {
      path: '500',
      element: <MaintenanceError500 />,
    },
    {
      path: 'under-construction',
      element: <MaintenanceUnderConstruction />,
    },
    {
      path: 'coming-soon',
      element: <MaintenanceComingSoon />,
    },
  ],
};

export default CommonRoutes;
