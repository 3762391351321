import PropTypes from 'prop-types';
import config from 'config';

const getTenant = (tenant) => {
  const { environment, environmentList } = config;

  if (environmentList.includes(tenant)) {
    return `core.${environment}`;
  }

  return `${tenant}.${environment}`;
};

getTenant.propTypes = {
  tenant: PropTypes.string,
};

export default getTenant;
