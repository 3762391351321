import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0Logout = () => {
  const { logout, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  if (isAuthenticated) {
    try {
      logout();
    } catch (error) {
      navigate('/');
    }
  } else {
    navigate('/');
  }
};

export default Auth0Logout;
