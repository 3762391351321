import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';
import { getPendingLinksList, getPendingLinksStatistics, renewLink } from '_api/admin/pending-links';

const initialState = {
  pendingLinksList: [],
  pendingLinksListMeta: {},
  pendingLinksStatistics: null,
  pendingLinksListStatus: XHR_STATUS.idle,
  renewLinkStatus: XHR_STATUS.idle,
};

const pendingLinks = createSlice({
  name: 'pendingLinks',
  initialState,
  reducers: {
    resetPendingLinksListData: () => ({
      ...initialState,
    }),
  },
  extraReducers: {
    [getPendingLinksList.pending]: state => ({
      ...state,
      pendingLinksListStatus: XHR_STATUS.pending,
    }),
    [getPendingLinksList.fulfilled]: (state, action) => {
      const {
        data: pendingLinksList = [],
        meta: pendingLinksListMeta = {},
        isLazyLoad = false,
      } = action.payload ?? {};

      return {
        ...state,
        pendingLinksList: isLazyLoad
          ? [...(state.pendingLinksList ?? []), ...pendingLinksList]
          : pendingLinksList,
        pendingLinksListMeta,
        pendingLinksListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getPendingLinksList.rejected]: state => (
      {
        ...state,
        pendingLinksListStatus: XHR_STATUS.rejected,
      }
    ),

    [getPendingLinksStatistics.fulfilled]: (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        pendingLinksStatistics: data,
      };
    },

    [renewLink.pending]: state => ({
      ...state,
      renewLinkStatus: XHR_STATUS.pending,
    }),
    [renewLink.fulfilled]: state => ({
      ...state,
      renewLinkStatus: XHR_STATUS.fulfilled,
    }),
    [renewLink.rejected]: state => ({
      ...state,
      renewLinkStatus: XHR_STATUS.rejected,
    }),
  },
});

export default pendingLinks.reducer;

export const { resetPendingLinksListData } = pendingLinks.actions;
