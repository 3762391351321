const XHR_STATUS_IDLE = 'XHR_STATUS_IDLE';
const XHR_STATUS_PENDING = 'XHR_STATUS_PENDING';
const XHR_STATUS_FULFILLED = 'XHR_STATUS_FULFILLED';
const XHR_STATUS_REJECTED = 'XHR_STATUS_REJECTED';

export const XHR_STATUS = {
  idle: XHR_STATUS_IDLE,
  pending: XHR_STATUS_PENDING,
  fulfilled: XHR_STATUS_FULFILLED,
  rejected: XHR_STATUS_REJECTED,
};
// TODO: Move to separate file depend on theme config or snackbar config
export const ALERT_COLOR = {
  [XHR_STATUS_FULFILLED]: 'success',
  [XHR_STATUS_REJECTED]: 'error',
};
