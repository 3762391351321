// enum for check locked user or not
export const LockedIdx = {
  locked: '1',
  unLocked: '0',
};

// user roles tooltip description
export const UserRolesDesc = {
  no_access: 'Identifies the user who does not have any permissions in this domain.',
  domain_admin: 'Identifies the user who has access to all permissions in this domain.',
  link_admin: 'Identifies the user who has certain permissions and access to all available links in this domain.',
  reporting_user: 'Identifies the user who has access to view all pages in this domain.',
  standard_user: 'Identifies a user who has some basic permissions in this domain.',
};

export const UserLayer = {
  Domain: 'domain',
  Account: 'account',
};

export const UserRoles = {
  BillingManager: 'billing_manager',
  AccountAdmin: 'account_admin',
  DomainAdmin: 'domain_admin',
  ReportingUser: 'reporting_user',
  LinkAdmin: 'link_admin',
  StandardUser: 'standard_user',
};
