import { createSlice } from '@reduxjs/toolkit';
import { getFeatures } from '_api/features';

const initialState = {
  features: null,
  getFeaturesLoading: false,
  getFeaturesSuccess: false,
  getFeaturesError: false,
  getFeaturesErrorMessage: null,
};

const handlePendingFeatures = (state) => {
  state.getFeaturesLoading = true;
  state.getFeaturesSuccess = false;
  state.getFeaturesError = false;
  state.getFeaturesErrorMessage = null;
};

const handleSuccessFeatures = (state, action) => {
  state.getFeaturesLoading = false;
  state.getFeaturesSuccess = true;
  state.getFeaturesError = false;
  state.getFeaturesErrorMessage = null;
  state.features = action.payload;
};

const handleErrorFeatures = (state) => {
  state.getFeaturesLoading = false;
  state.getFeaturesSuccess = false;
  state.getFeaturesError = true;
  state.getFeaturesErrorMessage = 'Failed to get features availble for the current scope.';
};

const feature = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    resetFeatures: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatures.pending, handlePendingFeatures);
    builder.addCase(getFeatures.fulfilled, handleSuccessFeatures);
    builder.addCase(getFeatures.rejected, handleErrorFeatures);
  },
});

export default feature.reducer;

export const { resetFeatures } = feature.actions;
