import { createSlice } from '@reduxjs/toolkit';
import { createTemplate, editTemplate, getTemplatesList, removeTemplate } from '_api/admin/link-templates';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  // Get linkTemplates
  templatesList: [],
  templatesListStatus: XHR_STATUS.idle,
  getTemplatesLoading: false,
  getTemplatesSuccess: false,
  getTemplatesError: false,
  // Create template
  createTemplate: {
    createTemplateLoading: false,
    createTemplateSuccess: false,
    createTemplateError: false,
    errorMessage: '',
  },
  // Edit template
  editTemplate: {
    editTemplateLoading: false,
    editTemplateSuccess: false,
    editTemplateError: false,
    errorMessage: '',
  },
  // Remove template
  removeTemplate: {
    removeTemplateLoading: false,
    removeTemplateSuccess: false,
    removeTemplateError: false,
  },
};

const linkTemplates = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    resetCreateTemplateData: state => ({
      ...state,
      createTemplate: {
        createTemplateLoading: false,
        createTemplateSuccess: false,
        createTemplateError: false,
      },
    }),
    resetEditTemplateData: state => ({
      ...state,
      editTemplate: {
        editTemplateLoading: false,
        editTemplateSuccess: false,
        editTemplateError: false,
      },
    }),
    resetRemoveTemplateData: state => ({
      ...state,
      removeTemplate: {
        removeTemplateLoading: false,
        removeTemplateSuccess: false,
        removeTemplateError: false,
      },
    }),
    resetTemplatesListData: state => ({
      ...state,
      templatesList: [],
      getTemplatesLoading: false,
      getTemplatesSuccess: false,
      getTemplatesError: false,
    }),
  },
  extraReducers: {
    // Get linkTemplates
    [getTemplatesList.pending]: state => ({
      ...state,
      templatesList: [],
      getTemplatesLoading: true,
      getTemplatesSuccess: false,
      getTemplatesError: false,
      templatesListStatus: XHR_STATUS.pending,
    }),
    [getTemplatesList.fulfilled]: (state, action) => {
      const { data: templatesList = null } = action.payload ?? {};
      return {
        ...state,
        templatesList,
        getTemplatesLoading: false,
        getTemplatesSuccess: true,
        getTemplatesError: false,
        templatesListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getTemplatesList.rejected]: state => ({
      ...state,
      getTemplatesLoading: false,
      getTemplatesSuccess: false,
      getTemplatesError: true,
      templatesListStatus: XHR_STATUS.rejected,
    }),
    // Create template
    [createTemplate.pending]: state => ({
      ...state,
      createTemplate: {
        editTemplateLoading: true,
        editTemplateSuccess: false,
        editTemplateError: false,
      },
    }),
    [createTemplate.fulfilled]: (state, action) => {
      const { data: newTemplateData = {} } = action.payload ?? {};

      return {
        ...state,
        templatesList: [...(state.templatesList || []), newTemplateData],
        createTemplate: {
          createTemplateLoading: false,
          createTemplateSuccess: true,
          createTemplateError: false,
        },
      };
    },
    [createTemplate.rejected]: (state, action) => ({
      ...state,
      createTemplate: {
        createTemplateLoading: false,
        createTemplateSuccess: false,
        createTemplateError: true,
        errorMessage: action.payload.message,
      },
    }),
    // edit template
    [editTemplate.pending]: state => ({
      ...state,
      editTemplate: {
        editTemplateLoading: true,
        editTemplateSuccess: false,
        editTemplateError: false,
      },
    }),
    [editTemplate.fulfilled]: state => ({
      ...state,
      editTemplate: {
        editTemplateLoading: false,
        editTemplateSuccess: true,
        editTemplateError: false,
      },
    }),
    [editTemplate.rejected]: (state, action) => ({
      ...state,
      editTemplate: {
        editTemplateLoading: false,
        editTemplateSuccess: false,
        editTemplateError: true,
        errorMessage: action.payload.message,
      },
    }),
    // remove template
    [removeTemplate.pending]: state => ({
      ...state,
      removeTemplate: {
        removeTemplateLoading: true,
        removeTemplateSuccess: false,
        removeTemplateError: false,
      },
    }),
    [removeTemplate.fulfilled]: state => ({
      ...state,
      removeTemplate: {
        removeTemplateLoading: false,
        removeTemplateSuccess: true,
        removeTemplateError: false,
      },
    }),
    [removeTemplate.rejected]: state => ({
      ...state,
      removeTemplate: {
        removeTemplateLoading: false,
        removeTemplateSuccess: false,
        removeTemplateError: true,
      },
    }),
  },
});

export const { resetCreateTemplateData, resetEditTemplateData, resetRemoveTemplateData } = linkTemplates.actions;

export const getTemplates = state => state.templates.templatesList;

export default linkTemplates.reducer;
