import { createSlice } from '@reduxjs/toolkit';
import {
  getIpExclusionsList,
  submitIpExclsuion,
  bulkDeleteIpExclusions,
  deleteIpExclusion,
  getIpExclusionById,
  getIpExclusionStatistics,
  importCSV,
} from '_api/admin/ip-exclusions';

const initialState = {
  ipExclusionsList: null,
  singleIpExclusionData: null,
  ipExclusionsListMeta: {},
  ipExclusionsStatistics: null,

  ipExclusionsStatisticsLoading: true,
  ipExclusionsStatisticsSuccess: false,
  ipExclusionsStatisticsError: false,

  getIpExclusionsListLoading: true,
  getIpExclusionsListSuccess: false,
  getIpExclusionsListError: false,

  getIpExclusionByIdLoading: false,
  getIpExclusionByIdSuccess: false,
  getIpExclusionByIdError: false,

  submitIpExclusion: {
    submitIpExclusionLoading: false,
    submitIpExclusionSuccess: false,
    submitIpExclusionError: false,
  },

  bulkDeleteIpExclusions: {
    bulkDeleteIpExclusionsLoading: true,
    bulkDeleteIpExclusionsSuccess: false,
    bulkDeleteIpExclusionsError: false,
  },

  deleteIpExclusion: {
    deleteIpExclusionLoading: false,
    deleteIpExclusionSuccess: false,
    deleteIpExclusionError: false,
  },

  importCSV: {
    importCSVLoading: false,
    importCSVSuccess: false,
    importCSVError: false,
  },
};

const ipExclusions = createSlice({
  name: 'ipExclusions',
  initialState,
  reducers: {
    resetIpExclusionsListData: () => ({
      ...initialState,
    }),
    resetSubmitIpExclusionData: state => ({
      ...state,
      submitIpExclusion: initialState.submitIpExclusion,
    }),
    resetDeleteIpExclusionData: state => ({
      ...state,
      deleteIpExclusion: initialState.deleteIpExclusion,
    }),
    resetSingleIpExclusionData: state => ({
      ...state,
      singleIpExclusionData: initialState.singleIpExclusionData,
    }),
  },
  extraReducers: {
    [getIpExclusionsList.pending]: state => ({
      ...state,
      getIpExclusionsListLoading: true,
      getIpExclusionsListSuccess: false,
      getIpExclusionsListError: false,
    }),
    [getIpExclusionsList.fulfilled]: (state, action) => {
      const { data: ipExclusionsList = [], meta: ipExclusionsListMeta = {}, isLazyLoad = false } = action.payload ?? {};

      return {
        ...state,
        ipExclusionsList: isLazyLoad ? [...(state.ipExclusionsList ?? []), ...ipExclusionsList] : ipExclusionsList,
        ipExclusionsListMeta,
        getIpExclusionsListLoading: false,
        getIpExclusionsListSuccess: true,
        getIpExclusionsListError: false,
      };
    },
    [getIpExclusionsList.rejected]: state => ({
      ...state,
      getIpExclusionsListLoading: false,
      getIpExclusionsListSuccess: false,
      getIpExclusionsListError: true,
    }),
    [submitIpExclsuion.pending]: state => ({
      ...state,
      submitIpExclusion: {
        submitIpExclusionLoading: true,
        submitIpExclusionSuccess: false,
        submitIpExclusionError: false,
      },
    }),
    [submitIpExclsuion.fulfilled]: state => ({
      ...state,
      submitIpExclusion: {
        submitIpExclusionLoading: false,
        submitIpExclusionSuccess: true,
        submitIpExclusionError: false,
      },
    }),
    [submitIpExclsuion.rejected]: state => ({
      ...state,
      submitIpExclusion: {
        submitIpExclusionLoading: false,
        submitIpExclusionSuccess: false,
        submitIpExclusionError: true,
      },
    }),

    [bulkDeleteIpExclusions.pending]: state => ({
      ...state,
      bulkDeleteIpExclusions: {
        bulkDeleteIpExclusionsLoading: true,
        bulkDeleteIpExclusionsSuccess: false,
        bulkDeleteIpExclusionsError: false,
      },
    }),
    [bulkDeleteIpExclusions.fulfilled]: state => ({
      ...state,
      bulkDeleteIpExclusions: {
        bulkDeleteIpExclusionsLoading: false,
        bulkDeleteIpExclusionsSuccess: true,
        bulkDeleteIpExclusionsError: false,
      },
    }),
    [bulkDeleteIpExclusions.rejected]: state => ({
      ...state,
      bulkDeleteIpExclusions: {
        bulkDeleteIpExclusionsLoading: false,
        bulkDeleteIpExclusionsSuccess: false,
        bulkDeleteIpExclusionsError: true,
      },
    }),

    [deleteIpExclusion.pending]: state => ({
      ...state,
      deleteIpExclusion: {
        deleteIpExclusionLoading: true,
        deleteIpExclusionSuccess: false,
        deleteIpExclusionError: false,
      },
    }),
    [deleteIpExclusion.fulfilled]: state => ({
      ...state,
      deleteIpExclusion: {
        deleteIpExclusionLoading: false,
        deleteIpExclusionSuccess: true,
        deleteIpExclusionError: false,
      },
    }),
    [deleteIpExclusion.rejected]: state => ({
      ...state,
      deleteIpExclusion: {
        deleteIpExclusionLoading: false,
        deleteIpExclusionSuccess: false,
        deleteIpExclusionError: true,
      },
    }),

    [getIpExclusionById.pending]: state => ({
      ...state,
      getIpExclusionByIdLoading: true,
      getIpExclusionByIdSuccess: false,
      getIpExclusionByIdError: false,
    }),
    [getIpExclusionById.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        singleIpExclusionData: data,
        getIpExclusionByIdLoading: false,
        getIpExclusionByIdSuccess: true,
        getIpExclusionByIdError: false,
      };
    },
    [getIpExclusionById.rejected]: state => ({
      ...state,
      getIpExclusionByIdLoading: false,
      getIpExclusionByIdSuccess: false,
      getIpExclusionByIdError: true,
    }),

    [importCSV.pending]: state => ({
      ...state,
      importCSV: {
        importCSVLoading: true,
        importCSVSuccess: false,
        importCSVError: false,
      },
    }),
    [importCSV.fulfilled]: state => ({
      ...state,
      importCSV: {
        importCSVLoading: false,
        importCSVSuccess: true,
        importCSVError: false,
      },
    }),
    [importCSV.rejected]: state => ({
      ...state,
      importCSV: {
        importCSVLoading: false,
        importCSVSuccess: false,
        importCSVError: true,
      },
    }),
    [getIpExclusionStatistics.pending]: state => ({
      ...state,
      ipExclusionsStatisticsLoading: true,
      ipExclusionsStatisticsSuccess: false,
      ipExclusionsStatisticsError: false,
    }),
    [getIpExclusionStatistics.fulfilled]: (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        ipExclusionsStatistics: data,
        ipExclusionsStatisticsLoading: false,
        ipExclusionsStatisticsSuccess: true,
        ipExclusionsStatisticsError: false,
      };
    },
    [getIpExclusionStatistics.rejected]: state => ({
      ...state,
      ipExclusionsStatisticsLoading: false,
      ipExclusionsStatisticsSuccess: false,
      ipExclusionsStatisticsError: true,
    }),
  },
});

export default ipExclusions.reducer;

export const {
  resetSingleIpExclusionData,
  resetIpExclusionsListData,
  resetDeleteIpExclusionData,
  resetSubmitIpExclusionData,
} = ipExclusions.actions;
