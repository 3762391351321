/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

import { removeEmptyValues } from '../utils/objectUtils';

export const getToken = createAsyncThunk('analytics/getToken', async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
      analytics: { filterBy = 'and', filterOptions, startDate, endDate },
    } = getState();

    const filterOptionsForSending = filterOptions.map(opt => ({
      value: opt.values,
      column: opt.attributes,
      type: opt.type,
      operator: opt.operator,
    }));

    const queryParams = {
      filters: filterOptionsForSending,
      filter_link: filterBy,
      start_date: startDate,
      end_date: endDate,
    };

    const { data: responseData } = await axiosInstance.get(`/${domainID}/links/list-ids`, {
      params: removeEmptyValues(queryParams),
    });

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getOverviewChartData = createAsyncThunk(
  'analytics/getOverviewChartData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token, tableType },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/charts/${tableType}?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getAnalyticsWidgetData = createAsyncThunk(
  'analytics/getAnalyticsWidgetData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/main?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getOverviewTableData = createAsyncThunk(
  'analytics/getOverviewTableData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token, currentPage, tableType },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/table/${tableType}?page=${currentPage}&start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTopAnalyticsData = createAsyncThunk(
  'analytics/getTopAnalyticsData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/table/top?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getAnalyticsOSData = createAsyncThunk(
  'analytics/getAnalyticsOSData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/os/chart?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getAnalyticsDeviceData = createAsyncThunk(
  'analytics/getAnalyticsDeviceData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/device/chart?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTagsTableData = createAsyncThunk(
  'analytics/getTagsTableData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/tags/table?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getReferrersChartData = createAsyncThunk(
  'analytics/getReferrersChartData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/referrer/chart?start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getMapData = createAsyncThunk('analytics/getMapData', async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { domainID = '' },
      analytics: { startDate, endDate, token },
    } = getState();

    const { data: responseData } = await axiosInstance.get(
      `/${domainID}/stats/location/map-country?start_date=${startDate}&end_date=${endDate}&token=${token}`,
    );

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const getTopLocationData = createAsyncThunk(
  'analytics/getTopLocationData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token, locationType },
      } = getState();

      const responsesArray = await Promise.allSettled(
        ['click', 'engagement', 'scan'].map(
          async type =>
            await axiosInstance.get(
              `/${domainID}/stats/location/${locationType}?type=${type}&start_date=${startDate}&end_date=${endDate}&token=${token}`,
            ),
        ),
      );

      return {
        data: {
          engagement: responsesArray[1].value.data.data.engagement,
          click: responsesArray[0].value.data.data.click,
          scan: responsesArray[2].value.data.data.scan,
        },
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTopCountriesData = createAsyncThunk(
  'analytics/getTopCountriesData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token, countryTopType },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/location/top-country?type=${countryTopType}&start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const getTopCitiesData = createAsyncThunk(
  'analytics/getTopCitiesData',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
        analytics: { startDate, endDate, token, cityTopType },
      } = getState();

      const { data: responseData } = await axiosInstance.get(
        `/${domainID}/stats/location/top-city?type=${cityTopType}&start_date=${startDate}&end_date=${endDate}&token=${token}`,
      );

      return responseData;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);
