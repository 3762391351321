import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../utils/axios';

export const getDashboardOverviewDataList = createAsyncThunk(
  'dashboard/getDashboardOverviewDataList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID = '' },
      } = getState();
      const response = await axiosInstance.get(`/domains/${domainID}/overview`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
