import axios from 'axios';
import config from 'config';

import { UserLayer } from '../pages/admin/constant';

const { apiHost: baseURL } = config;

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'X-Host': `${document.location.protocol}//${document.location.host}`,
  },
});

axiosInstance.interceptors.response.use(
  response => response,
  async (error) => {
    const { response: { status = '' } = {} } = error ?? {};
    if (status === 403) {
      window.location.replace('/maintenance/403');
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use((config) => {
  store.getState().tenant.tenant && (config.headers['X-Tenant'] = store.getState().tenant.tenant);
  store.getState().auth.domainID && (config.headers['X-Domain'] = store.getState().auth.domainID);

  const accountRoleType = store.getState().auth.userRoleType;

  if (accountRoleType === UserLayer.Account) {
    store.getState().auth.user?.account?.uuid &&
      (config.headers['X-Account'] = store.getState().auth.user.account.uuid);
  }

  try {
    if (localStorage.getItem('userData')) {
      const { tokenType = 'Bearer', accessToken } = JSON.parse(localStorage.getItem('userData'));
      if (accessToken) {
        config.headers.Authorization = `${tokenType} ${accessToken}`;
      }
    }
    return config;
  } catch (error) {
    console.log(error);
    return config;
  }
});

export default axiosInstance;
