export const getNotificationColor = (notificationType) => {
  switch (notificationType) {
    case 'success':
      return '#19B08A';
    case 'error':
      return '#FF4D4F';
    default:
      return '#453F91';
  }
};

//  User profile card  link index  ----------------
export const UserProfileCardIdx = {
  MyAccount: 0,
  LogOut: 2,
};
