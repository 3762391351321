import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';
import {
  getDestinationsList,
  submitTrustedDestination,
  deleteTrustedDestination,
  getTrustedDestinationStatistics,
  getTrustedDestinationById,
  updateTrustedDestinationById,
  getELinkTemplates,
  getTrustedDomains,
  checkLongURLs,
  getELinkTrustedDestinations,
} from '_api/admin/trusted-destinations';

const initialState = {
  destinationsList: [],
  trustedLongURLs: [],
  destinationsListMeta: {},
  destinationsListStatus: XHR_STATUS.idle,
  submitTrustedDestinationStatus: XHR_STATUS.idle,
  deleteTrustedDestinationStatus: XHR_STATUS.idle,
  updateTDStatus: XHR_STATUS.idle,

  elinkTDList: [],
  elinkTDListMeta: {},
  elinkTDListStatus: XHR_STATUS.idle,

  submitTrustedDestinationError: '',
  updateTrustedDestinationError: '',

  trustedDestinationsStatistics: null,
  singleTrustedDestinationData: null,
  elinkTemplatesData: null,
  trustedDomains: [],
};

const trustedDestinations = createSlice({
  name: 'trustedDestinations',
  initialState,
  reducers: {
    resetTrustedDestinationListData: () => ({
      ...initialState,
    }),
    resetErrorMessages: state => ({
      ...state,
      submitTrustedDestinationError: '',
      updateTrustedDestinationError: '',
    }),
    resetTrustedLongURLs: state => ({
      ...state,
      trustedLongURLs: [],
    }),
    resetSubmitDestinationData: state => ({
      ...state,
      submitTrustedDestination: initialState.submitTag,
      submitTrustedDestinationStatus: XHR_STATUS.idle,
    }),
    resetUpdateDestinationData: state => ({
      ...state,
      updateTrustedDestinationById: initialState.updateTag,
      updateTDStatus: XHR_STATUS.idle,
    }),
    resetDeleteDestinationData: state => ({
      ...state,
      deleteTrustedDestination: initialState.deleteTag,
      deleteTrustedDestinationStatus: XHR_STATUS.idle,
    }),
  },
  extraReducers: {
    [getDestinationsList.pending]: state => ({
      ...state,
      destinationsListStatus: XHR_STATUS.pending,
    }),
    [getDestinationsList.fulfilled]: (state, action) => {
      const {
        data: destinationsList = [],
        meta: destinationsListMeta = {},
        isLazyLoad = false,
      } = action.payload ?? {};

      return {
        ...state,
        destinationsList: isLazyLoad
          ? [...(state.destinationsList ?? []), ...destinationsList]
          : destinationsList,
        destinationsListMeta,
        destinationsListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getDestinationsList.rejected]: state => ({
      ...state,
      destinationsListStatus: XHR_STATUS.rejected,
    }),

    [submitTrustedDestination.pending]: state => ({
      ...state,
      submitTrustedDestinationStatus: XHR_STATUS.pending,
    }),
    [submitTrustedDestination.fulfilled]: state => ({
      ...state,
      submitTrustedDestinationStatus: XHR_STATUS.fulfilled,
    }),
    [submitTrustedDestination.rejected]: (state,action) => ({
      ...state,
      submitTrustedDestinationStatus: XHR_STATUS.rejected,
      submitTrustedDestinationError: action.payload?.message,
    }),

    [checkLongURLs.fulfilled]: (state, action) => ({
      ...state,
      trustedLongURLs: action.payload,
    }),

    [updateTrustedDestinationById.pending]: state => ({
      ...state,
      updateTDStatus: XHR_STATUS.pending,
    }),
    [updateTrustedDestinationById.fulfilled]: state => ({
      ...state,
      updateTDStatus: XHR_STATUS.fulfilled,
    }),
    [updateTrustedDestinationById.rejected]: (state, action) => (
      {
        ...state,
        updateTDStatus: XHR_STATUS.rejected,
        updateTrustedDestinationError: action.payload?.message,
      }
    ),

    //  Delete Destination
    [deleteTrustedDestination.pending]: state => ({
      ...state,
      deleteTrustedDestinationStatus: XHR_STATUS.pending,
    }),
    [deleteTrustedDestination.fulfilled]: state => ({
      ...state,
      deleteTrustedDestinationStatus: XHR_STATUS.fulfilled,
    }),
    [deleteTrustedDestination.rejected]: state => ({
      ...state,
      deleteTrustedDestinationStatus: XHR_STATUS.rejected,
    }),
    
    [getTrustedDestinationStatistics.fulfilled]: (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        trustedDestinationsStatistics: data,
      };
    },
    [getTrustedDestinationById.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        singleTrustedDestinationData: data,
      };
    },

    [getELinkTemplates.pending]: state => ({
      ...state,
    }),
    [getELinkTemplates.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        elinkTemplatesData: data,
      };
    },
    [getELinkTemplates.rejected]: state => ({
      ...state,
    }),

    [getTrustedDomains.pending]: state => ({
      ...state,
    }),
    [getTrustedDomains.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        trustedDomains: data,
      };
    },
    [getTrustedDomains.rejected]: state => ({
      ...state,
    }),

    [getELinkTrustedDestinations.pending]: state => ({
      ...state,
      elinkTDListStatus: XHR_STATUS.pending,
    }),
    [getELinkTrustedDestinations.fulfilled]: (state, action) => {
      const {
        data: elinkTDList = [],
        meta: elinkTDListMeta = {},
        isLazyLoad = false,
      } = action.payload ?? {};

      return {
        ...state,
        elinkTDList: isLazyLoad
          ? [...state.elinkTDList, ...elinkTDList]
          : elinkTDList,
        elinkTDListMeta,
        elinkTDListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getELinkTrustedDestinations.rejected]: state => ({
      ...state,
      elinkTDListStatus: XHR_STATUS.rejected,
    }),
  },
});

export default trustedDestinations.reducer;

export const {
  resetTrustedDestinationListData,
  resetDeleteDestinationData,
  resetSubmitDestinationData,
  resetErrorMessages,
  resetTrustedLongURLs,
} = trustedDestinations.actions;
