import PropTypes from 'prop-types';

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Default = colors => {
  const { red, gold, cyan, green, grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  return {
    primary: {
      lighter: '#87ecd3',
      100: '#71e8cb',
      200: '#45e1bb',
      light: '#2fdeb3',
      400: '#22d2a6',
      main: '#1EBC95',
      dark: '#1aa684',
      700: '#179072',
      darker: '#137a61',
      900: '#137a61',
      contrastText
    },
    secondary: {
      lighter: '#b0add8',
      100: '#9f9ccf',
      200: '#7e79bf',
      light: '#6d68b7',
      400: '#5d57af',
      main: '#524CA0',
      600: '#49448f',
      dark: '#403c7d',
      800: '#37336c',
      darker: '#2e2b5b',
      A100: '#252349',
      A200: '#1d1a38',
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: '#19B08A',
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors
  };
};

Default.propTypes = {
  colors: PropTypes.object
};

export default Default;
