import { useSelector } from '../store';

const usePermissions = () => {
  const { role, roles, permissions, userRoleType, user } = useSelector(state => state.auth || {});

  return {
    role,
    roles,
    permissions,
    userRoleType,
    currentUser: user,
  };
};
export default usePermissions;
