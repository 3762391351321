export const ENDPOINTS = {
  getOne: uuid => `/qr/templates/${uuid}`,
  convertTypes: `/qr/convertFile`,
};

export const BE_FE_MAP = {
  getOne: {
    width: 'width',
    margin: 'margin',
    height: 'height',
    image_src: 'image',
    image_remove_background: 'imageOptions.hideBackgroundDots',
    image_margin: 'imageOptions.margin',
    shape_body: 'dotsOptions.type',
    color_body: 'dotsOptions.color',
    color_background: 'backgroundOptions.color',
    shape_outer_eye: 'cornersSquareOptions.type',
    color_outer_eye: 'cornersSquareOptions.color',
    shape_inner_eye: 'cornersDotOptions.type',
    color_inner_eye: 'cornersDotOptions.color',
  },
};

export const FE_BE_MAP = {
  targetFormat: 'target_format',
  sourceFormat: 'source_format',
  sourceData: 'source_file',
};


