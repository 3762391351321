// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          '& .MuiInputBase-input': {
            WebkitTextFillColor: '#453F91!important',
            borderColor: '#453F91!important'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#453F91!important'
          }
        },
        disabled: {
          '& .MuiInputBase-input': {
            borderColor: '#F0F0F0!important'
          },
          '&.MuiOutlinedInput-notchedOutline': {
            borderColor: '#F0F0F0!important'
          }
        },
        sizeSmall: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            marginBottom: 0
          }
        }
      }
    }
  };
}
