import PropTypes from 'prop-types';

// material-ui
import { ReactComponent as IconLogoSVG } from '../../assets/images/blink-icon.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoIcon = () => <IconLogoSVG width="100%" />;

LogoIcon.propTypes = {
  reverse: PropTypes.bool,
};

export default LogoIcon;
