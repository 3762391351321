import PropTypes from 'prop-types';

// material-ui
import { Box, CircularProgress, Typography } from '@mui/material';

// ==============================|| PROGRESS - CIRCULAR LABEL ||============================== //

export default function CircularWithLabel({ value, color, ...others }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        color={color ?? 'primary'}
        sx={{ width: '40px', height: '40px', p: '3.5px', '& circle': { strokeWidth: '1.5px' } }}
        variant="determinate"
        value={value}
        {...others}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color={color ?? 'primary'} fontSize={9} fontWeight={400}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularWithLabel.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};
