import { CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { InfoOutlined } from '@ant-design/icons';
import { Avatar, ListItemAvatar } from '@mui/material';
import PropTypes from 'prop-types';
import CircularWithLabel from 'components/@extended/Progress/CircularWithLabel';

export const NotificationIcon = ({ notification }) => {
  const { type: notificationType = '', data = {} } = notification ?? {};

  switch (notificationType) {
    case 'success':
      return (
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'success.main',
              bgcolor: 'rgba(25, 176, 138, 0.1)',
            }}
          >
            <CheckOutlined style={{ fontSize: 20, fill: '#19B08A' }} />
          </Avatar>
        </ListItemAvatar>
      );
    case 'error':
      return (
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'error.main',
              bgcolor: 'rgba(255, 77, 79, 0.1)',
            }}
          >
            <CloseOutlined style={{ fontSize: 20, fill: '#FF4D4F' }} />
          </Avatar>
        </ListItemAvatar>
      );
    case 'in_progress':
      return (
        <ListItemAvatar>
          <Avatar
            sx={{
              color: '#453F91',
              bgcolor: 'rgba(69, 63, 145, 0.1)',
            }}
          >
            <CircularWithLabel
              color="secondary"
              style={{ fontSize: 20, p: 0.5 }}
              variant="determinate"
              value={data?.progress ?? 0}
            />
          </Avatar>
        </ListItemAvatar>
      );
    default:
      return (
        <ListItemAvatar>
          <Avatar
            sx={{
              color: '#453F91',
              bgcolor: 'rgba(69, 63, 145, 0.1)',
            }}
          >
            <InfoOutlined style={{ fontSize: 20, color: '#453F91' }} />
          </Avatar>
        </ListItemAvatar>
      );
  }
};

NotificationIcon.propTypes = {
  notification: PropTypes.object,
};

NotificationIcon.defaultProps = {
  notification: {},
};
