import { Settings } from './constants';

const replaceSettingErrorMessage = (string, settings) => {
  let result = string;
  const genericRegex = new RegExp(`settings\\.\\d+\\.value`, 'g');

  result = result.replace(genericRegex, (match) => {
    const [index] = match.match(/\d+/);
    return Settings[settings[index].name];
  });

  return result;
};

export const replaceSettingsName = (name, string, settings) => {
  if (!name) {
    return replaceSettingErrorMessage(string, settings);
  }

  const originalSettingIndex = name.match(/\d+/);

  if (originalSettingIndex && originalSettingIndex[0]) {
    return [`settings.${settings[originalSettingIndex].name}.value`, replaceSettingErrorMessage(string, settings)];
  }

  return [name, string];
};
