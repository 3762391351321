import cloneDeep from 'lodash/cloneDeep';
// TODO: remove
Array.prototype.move = function(from, to) {
  const newArray = cloneDeep(this);
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);
  return newArray;
};

Array.prototype.removeItemWithIndex = function(removedIndex) {
  return this.filter((_, elementIndex) => elementIndex !== removedIndex);
};
