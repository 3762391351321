import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../utils/axios';

export const exportAttributeList = createAsyncThunk(
  'admin/exportAttributeList',
  async ({ attributeFilter }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/attributes/export`, attributeFilter);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAttributesList = createAsyncThunk('admin/getAttributesList', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/attributes`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const submitAttribute = createAsyncThunk(
  'admin/submitAttribute',
  async ({ attributeData = {}, isEditMode = false }, { rejectWithValue }) => {
    try {
      let response;
      if (isEditMode) {
        const attributeID = attributeData.id ?? '';
        response = await axiosInstance.put(`/attributes/${attributeID}`, attributeData);
      } else {
        response = await axiosInstance.post('/attributes', attributeData);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getOneAttributeData = createAsyncThunk(
  'admin/getOneAttributeData',
  async ({ attributeID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/attributes/${attributeID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAttribute = createAsyncThunk(
  'admin/deleteAttribute',
  async ({ attributeID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/attributes/${attributeID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
