import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../utils/axios';

export const getFeatures = createAsyncThunk('features/getFeatures', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/features`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
