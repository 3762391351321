// ==============================|| OVERRIDES - DATAGRID ||============================== //

export default function MuiDataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#EDECF5'
          },
          '& .MuiDataGrid-pinnedColumnHeaders': {
            boxShadow: 'none',
            backgroundColor: '#EDECF5 !important'
          },
          fontSize: '1rem',
          '.MuiDataGrid-columnHeader:focus-within': { outline: 'none  !important' },
          '.MuiDataGrid-cellCheckbox:focus-within': { outline: 'none  !important' },
          '.MuiDataGrid-cellCheckbox': { backgroundColor: 'inherit' },
          '& .MuiCheckbox-colorPrimary:hover': { backgroundColor: 'transparent' },
          '& .MuiDataGrid-pinnedColumns:has(.MuiDataGrid-cellCheckbox)': {
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          '& .MuiDataGrid-pinnedColumnHeaders:has(.MuiDataGrid-columnHeaderCheckbox)': {
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center'
          }
        },
        panel: {
          '.MuiDataGrid-columnsPanelRow:has(.Mui-disabled)': { display: 'none' }
        }
      }
    }
  };
}
