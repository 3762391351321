import { createSlice } from '@reduxjs/toolkit';
import { getDashboardOverviewDataList } from '_api/dashboard';

const initialState = {
  dashboardOverviewData: {},
  getDashboardOverviewDataLoading: false,
  getDashboardOverviewDataSuccess: false,
  getDashboardOverviewDataError: false,
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboardOverviewDataListData: state => ({
      ...state,
      dashboardOverviewData: {},
      getDashboardOverviewDataLoading: false,
      getDashboardOverviewDataSuccess: false,
      getDashboardOverviewDataError: false,
    }),
  },
  extraReducers: {
    // Get dashboard overview data
    [getDashboardOverviewDataList.pending]: state => ({
      ...state,
      dashboardOverviewData: {},
      getDashboardOverviewDataLoading: true,
      getDashboardOverviewDataSuccess: false,
      getDashboardOverviewDataError: false,
    }),
    [getDashboardOverviewDataList.fulfilled]: (state, action) => {
      const { data: dashboardOverviewData = {} } = action.payload ?? {};

      return {
        ...state,
        dashboardOverviewData,
        getDashboardOverviewDataLoading: false,
        getDashboardOverviewDataSuccess: true,
        getDashboardOverviewDataError: false,
      };
    },
    [getDashboardOverviewDataList.rejected]: state => ({
      ...state,
      getDashboardOverviewDataLoading: false,
      getDashboardOverviewDataSuccess: false,
      getDashboardOverviewDataError: true,
    }),
  },
});

export const { resetDashboardOverviewDataListData } = dashboard.actions;

export default dashboard.reducer;
