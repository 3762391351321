import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import config from 'config';

import LogoMain from './LogoMain';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, sx, to, color }) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    <LogoMain reverse={reverse} color={color} />
  </ButtonBase>
);

export default LogoSection;
