import { createSlice } from '@reduxjs/toolkit';

const features = (function getExperimentalFeatures() {
  const request = new XMLHttpRequest();
  request.open('GET', document.location, false);
  request.send(null);

  return (request.getResponseHeader('Experimental-Features') || '').split(',');
}());

const initialState = {
  vcard: features.includes('vcard'),
};

const experimentalFeatures = createSlice({
  name: 'experimentalFeatures',
  initialState,
});

export default experimentalFeatures.reducer;
