import { createSelector } from '@reduxjs/toolkit';
import featuresCods from 'constants/features';

export const featureSelector = state => state.feature;

export const hasObservePointFeatureSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.OBSERVE_POINT) !== undefined,
);

export const hasTrustedDestinationSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.TRUSTED_DESTINATIONS) !== undefined,
);

export const hasPendingLinksSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === featuresCods.PENDING_LINKS) !== undefined,
);

export const hasELinksSelector = createSelector(
  state => state.feature?.features || [],
  features => features.find(feature => feature.code === 'elink') !== undefined,
);
