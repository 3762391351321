import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTenant from 'hooks/useTenant';
import config from 'config';
import getTenant from 'utils/getTenant';
import * as Sentry from '@sentry/react';

import { setIsTenantSite, setTenant } from '../store/reducers/tenant';
import { dispatch } from '../store';

const { baseHost, tenants } = config;

const TenantGuard = ({ children }) => {
  const { tenant } = useTenant();
  useEffect(() => {
    const currentHost = new URL(window.location.href.toString()).hostname;

    // If the current host is not the base host and not localhost, then we may be on a tenant site
    if (!currentHost.includes('localhost') && currentHost !== baseHost) {
      const domainTenant = getTenant(currentHost.split('.')[0] || ''); // Get the top level subdomain
      if (tenants.includes(domainTenant) && tenant !== domainTenant) {
        dispatch(setIsTenantSite(true));
        dispatch(setTenant(domainTenant));
        if (['local', 'ci', 'testing', 'test'].includes(config.environment) === false) {
          Sentry.setTag('tenant', domainTenant);
        }
      }
    }
  });

  return children;
};
TenantGuard.propTypes = {
  children: PropTypes.node,
};

export default TenantGuard;
