import { useSelector } from 'react-redux';
import config from 'config';

const useTenant = () => {
  const store = useSelector(state => state.tenant);
  const { tenant, isTenantSite } = store;
  const { baseHost, tenants } = config;

  const validateTenant = tenant => tenants.includes(tenant);

  const tenantDomain = () => {
    const currentHost = new URL(window.location.href.toString()).hostname;
    if (!currentHost.includes('localhost') && currentHost !== baseHost) {
      const [domainTenant] = currentHost.split('.');
      if (validateTenant(domainTenant)) {
        return domainTenant;
      }
    }
  };

  // useEffect(() => {
  //     if (tenantOverride != tenant && validateTenant(tenantOverride)) {
  //       useDispatch({ type: 'SET_IS_TENANT_SITE', payload: false });
  //       useDispatch({ type: 'SET_TENANT', payload: tenantOverride });
  //     }
  // }, [tenantOverride]);

  return { tenant, isTenantSite, validateTenant, tenantDomain };
};

export default useTenant;
