import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

import { removeEmptyValues } from '../../utils/objectUtils';

export const getQRTemplatesList = createAsyncThunk(
  'qrTemplates/getQRTemplatesList',
  async (
    {
      currentPage = 0,
      currentRowCount = 25,
      currentSearchValue = '',
      currentFilters = [],
      currentFilterLink = 'and',
      currentSortData: { field: sort = 'created_at', sort: sort_direction = 'desc' } = {},
    },
    { rejectWithValue },
  ) => {
    try {
      const queryParams = {
        page: currentPage,
        per_page: currentRowCount,
        search: currentSearchValue,
        filters: currentFilters,
        filter_link: currentFilterLink,
        sort,
        sort_direction,
      };

      const response = await axiosInstance.get(`/qr/templates`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getQRTemplateByID = createAsyncThunk(
  'qrTemplates/getQRTemplateByID',
  async ({ templateID = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/qr/templates/${templateID}`, {
        data: {},
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createQRTemplate = createAsyncThunk(
  'qrTemplates/createQRTemplate',
  async ({ templateData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/qr/templates`, templateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editQRTemplate = createAsyncThunk(
  'qrTemplates/editQRTemplate',
  async ({ templateData = {}, templateID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/qr/templates/${templateID}/`, templateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeQRTemplate = createAsyncThunk(
  'qrTemplates/removeQRTemplate',
  async ({ templateID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/qr/templates/${templateID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
