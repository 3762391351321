import useTenant from 'hooks/useTenant';
import config from 'config';
import { ReactComponent as LogoSVG } from 'assets/images/blink.svg';

const LogoMain = ({ tenant, color = '#322E8F' }) => {
  const { tenant: currentTenant } = useTenant();
  if (tenant) {
    const { staticHost } = config;
    return (
      <img
        src={`${staticHost}/logos/${currentTenant}.svg`}
        srcSet={`${staticHost}/logos/${currentTenant}.svg`}
        alt="logo"
        style={{
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    );
  } else {
    return <LogoSVG width="100%" fill={color}/>;
  }
};

export default LogoMain;
