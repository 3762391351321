import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTenantsList = createAsyncThunk('tenants/getTenantsList', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/tenants`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
