import { createSlice } from '@reduxjs/toolkit';

import {
  createLink,
  duplicateLink,
  editLink,
  fillLinkImportData,
  getLinkAttributeMapping,
  getRulesBaseStructure,
  getLinkAttributes,
  getLinksList,
  getSystemLinkAttributes,
  importLinks,
} from '_api/links';
import { getOneAttributeData } from '_api/admin/attributes';

import cloneDeep from 'lodash/cloneDeep';
import { appendNewFieldsObj } from 'utils/objectUtils';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  linksList: null,
  linksListMeta: [],
  linksListStatus: XHR_STATUS.idle,
  linkAttributesList: [],
  linkAttributesListStatus: XHR_STATUS.idle,
  // Get links
  getLinksLoading: false,
  getLinksSuccess: false,
  getLinksError: false,
  // Get link attributes
  getLinkAttributesLoading: false,
  getLinkAttributesSuccess: false,
  getLinkAttributesError: false,
  getOneAttributeStatus: XHR_STATUS.idle,
  // Create link
  createLinkForm: {},
  createLinkLoading: false,
  createLinkSuccess: false,
  createLinkError: false,
  // Edit link
  editLink: {
    editLinkLoading: false,
    editLinkSuccess: false,
    editLinkError: false,
  },
  // Import links
  importLinks: {
    importLinksLoading: false,
    importLinksSuccess: false,
    importLinksError: false,
  },
  // Link attribute mapping
  linkAttributeMapping: {
    headerFirstRow: null,
    linkAttributeMappingList: [],
    linkAttributeInvalidHeadersFile: '',
    linkImportData: [],
    getLinkAttributeMappingLoading: false,
    getLinkAttributeMappingSuccess: false,
    getLinkAttributeMappingError: false,
  },
  // Active link during opened drawer
  activeLinkItem: null,
  // initial values for generate variants form
  initialValuesGenerateVariants: null,
  isOwnedLink: false,
  // separated assign attributes for reusable CustomFormItem component
  assignedAttributesFormList: [],
  fillLinkImportData: {
    fillLinkImportDataLoading: false,
    fillLinkImportDataSuccess: false,
    fillLinkImportDataError: false,
  },
  // Generate Variants
  generateVariantsStatus: {
    generateVariantsLinkLoading: false,
    generateVariantsLinkSuccess: false,
    generateVariantsLinkError: '',
  },
  duplicateLink: {
    duplicateLinkLoading: false,
    duplicateLinkSuccess: false,
    duplicateLinkError: false,
  },
};
const links = createSlice({
  name: 'links',
  initialState,
  reducers: {
    resetCreateLinkData: state => ({
      ...state,
      createLinkLoading: false,
      createLinkSuccess: false,
      createLinkError: false,
    }),
    resetEditLinkData: state => ({
      ...state,
      editLink: {
        editLinkLoading: false,
        editLinkSuccess: false,
        editLinkError: false,
      },
    }),
    resetLinksListData: state => ({
      ...state,
      linksList: null,
      getLinksLoading: false,
      getLinksSuccess: false,
      getLinksError: false,
    }),
    setAssignedAttributes: (state, action) => ({
      ...state,
      assignedAttributesFormList: cloneDeep(action.payload),
    }),
    setActiveLinkItem: (state, action) => {
      const cloneAttributeValues = cloneDeep(action.payload?.attribute_values);
      const appendedOptionsList = appendNewFieldsObj(cloneAttributeValues, []);
      const isOwnedLink = action.payload.userId === action.payload.owner_id;

      return {
        ...state,
        activeLinkItem: { ...action.payload },
        initialValuesGenerateVariants: { ...appendedOptionsList },
        isOwnedLink,
      };
    },
    resetActiveLink: state => ({
      ...state,
      activeLinkItem: null,
      assignedAttributesFormList: [],
      initialValuesGenerateVariants: null,
      isOwnedLink: false,
    }),
    resetLinkAttributeMappingData: state => ({
      ...state,
      linkAttributeMapping: {
        linkAttributeMappingList: [],
        linkAttributeInvalidHeadersFile: '',
        getLinkAttributeMappingLoading: false,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: false,
      },
    }),
    resetFillLinkImportData: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: false,
      },
    }),
    updateLinkAttributeMapping: (state, action) => ({
      ...state,
      linkAttributeMapping: {
        ...state.linkAttributeMapping,
        ...action.payload,
      },
    }),
    resetDuplicateLinkData: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: false,
        duplicateLinkError: false,
      },
    }),
  },
  extraReducers: {
    // Get links
    [getLinksList.pending]: state => ({
      ...state,
      linksList: null,
      getLinksLoading: true,
      getLinksSuccess: false,
      getLinksError: false,
      linksListStatus: XHR_STATUS.pending,
    }),
    [getLinksList.fulfilled]: (state, action) => {
      const { data: linksList = null, meta: linksListMeta = [] } = action.payload ?? {};

      return {
        ...state,
        linksList,
        linksListMeta,
        getLinksLoading: false,
        getLinksSuccess: true,
        getLinksError: false,
        linksListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getLinksList.rejected]: state => ({
      ...state,
      getLinksLoading: false,
      getLinksSuccess: false,
      getLinksError: true,
      linksListStatus: XHR_STATUS.rejected,
    }),
    // Get link attributes
    [getLinkAttributes.pending]: state => ({
      ...state,
      getLinkAttributesLoading: true,
      linkAttributesListStatus: XHR_STATUS.pending,
    }),
    [getLinkAttributes.fulfilled]: (state, action) => {
      const { data: linkAttributesList = [] } = action.payload ?? {};

      return {
        ...state,
        linkAttributesList,
        getLinkAttributesLoading: false,
        getLinkAttributesSuccess: true,
        getLinkAttributesError: false,
        linkAttributesListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getLinkAttributes.rejected]: state => ({
      ...state,
      getLinkAttributesLoading: false,
      getLinkAttributesSuccess: false,
      getLinkAttributesError: true,
      linkAttributesListStatus: XHR_STATUS.rejected,
    }),
    [getOneAttributeData.pending]: (state) => {
      state.getOneAttributeStatus = XHR_STATUS.pending;
    },
    [getOneAttributeData.rejected]: (state) => {
      state.getOneAttributeStatus = XHR_STATUS.rejected;
    },
    [getOneAttributeData.fulfilled]: (state, action) => {
      const { data: attributeData = {} } = action.payload ?? {};

      const attributeIndex = state.linkAttributesList?.findIndex(({ id }) => id === attributeData.id);
      if (attributeIndex !== -1) {
        state.linkAttributesList[attributeIndex] = attributeData;
      }

      state.assignedAttributesFormList = state.assignedAttributesFormList.map(item => 
        (item.id === attributeData.id ? attributeData : item),
      );
      state.getOneAttributeStatus = XHR_STATUS.fulfilled;
    },
    // Get Rules
    [getRulesBaseStructure.fulfilled]: (state, action) => {
      const { data: rulesList = [] } = action.payload ?? {};

      return {
        ...state,
        rulesList,
        getRulesBaseStructureLoading: false,
        getRulesBaseStructureLoadingSuccess: true,
        getRulesBaseStructureLoadingError: false,
      };
    },
    [getRulesBaseStructure.rejected]: state => ({
      ...state,
      getRulesBaseStructureLoading: false,
      getRulesBaseStructureSuccess: false,
      getRulesBaseStructureError: true,
    }),
    [getSystemLinkAttributes.fulfilled]: (state, action) => {
      const { data: linkSystemAttributesList = [] } = action.payload ?? {};

      return {
        ...state,
        linkSystemAttributesList,
        getLinkAttributesLoading: false,
        getLinkAttributesSuccess: true,
        getLinkAttributesError: false,
      };
    },
    [getSystemLinkAttributes.rejected]: state => ({
      ...state,
      getLinkAttributesLoading: false,
      getLinkAttributesSuccess: false,
      getLinkAttributesError: true,
    }),
    // Create link
    [createLink.fulfilled]: (state, action) => {
      const { data: newLinkData = {} } = action.payload ?? {};

      return {
        ...state,
        linksList: [...(state.linksList || []), newLinkData],
        createLinkLoading: false,
        createLinkSuccess: true,
        createLinkError: false,
      };
    },
    [createLink.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        createLinkLoading: false,
        createLinkSuccess: false,
        createLinkError: message ?? 'Something went wrong while creating a link',
      };
    },
    // edit link
    [editLink.pending]: state => ({
      ...state,
      editLink: {
        editLinkLoading: true,
        editLinkSuccess: false,
        editLinkError: false,
      },
    }),
    [editLink.fulfilled]: state => ({
      ...state,
      editLink: {
        editLinkLoading: false,
        editLinkSuccess: true,
        editLinkError: false,
      },
    }),
    [editLink.rejected]: (state, action) => {
      const { message = '' } = action.payload;

      return {
        ...state,
        editLink: {
          editLinkLoading: false,
          editLinkSuccess: false,
          editLinkError: message ?? 'Something went wrong while editing a link',
        },
      };
    },
    // Import links
    [importLinks.pending]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: true,
        importLinksSuccess: false,
        importLinksError: false,
      },
    }),
    [importLinks.fulfilled]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: false,
        importLinksSuccess: true,
        importLinksError: false,
      },
    }),
    [importLinks.rejected]: state => ({
      ...state,
      importLinks: {
        importLinksLoading: false,
        importLinksSuccess: false,
        importLinksError: true,
      },
    }),
    // Get link attribute mapping list
    [getLinkAttributeMapping.pending]: state => ({
      ...state,
      linkAttributeMapping: {
        linkAttributeMappingList: [],
        getLinkAttributeMappingLoading: true,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: false,
      },
    }),
    [getLinkAttributeMapping.fulfilled]: (state, action) => {
      const {
        attributeMapping: { data: linkAttributeMappingList = [] } = {},
        invalidHeaders: { data: { file_path: linkAttributeInvalidHeadersFile = '' } = {} } = {},
      } = action.payload ?? {};
      return {
        ...state,
        linkAttributeMapping: {
          linkAttributeMappingList,
          linkAttributeInvalidHeadersFile,
          getLinkAttributeMappingLoading: false,
          getLinkAttributeMappingSuccess: true,
          getLinkAttributeMappingError: false,
        },
      };
    },
    [getLinkAttributeMapping.rejected]: state => ({
      ...state,
      linkAttributeMapping: {
        getLinkAttributeMappingLoading: false,
        getLinkAttributeMappingSuccess: false,
        getLinkAttributeMappingError: true,
      },
    }),
    // Fill link import data
    [fillLinkImportData.pending]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: true,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: false,
      },
    }),
    [fillLinkImportData.fulfilled]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: true,
        fillLinkImportDataError: false,
      },
    }),
    [fillLinkImportData.rejected]: state => ({
      ...state,
      fillLinkImportData: {
        fillLinkImportDataLoading: false,
        fillLinkImportDataSuccess: false,
        fillLinkImportDataError: true,
      },
    }),
    // Duplicate Link
    [duplicateLink.pending]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: true,
        duplicateLinkSuccess: false,
        duplicateLinkError: false,
      },
    }),
    [duplicateLink.fulfilled]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: true,
        duplicateLinkError: false,
      },
    }),
    [duplicateLink.rejected]: state => ({
      ...state,
      duplicateLink: {
        duplicateLinkLoading: false,
        duplicateLinkSuccess: false,
        duplicateLinkError: true,
      },
    }),
  },
});

export const {
  resetCreateLinkData,
  resetEditLinkData,
  resetLinksListData,
  resetLinkAttributeMappingData,
  resetFillLinkImportData,
  updateLinkAttributeMapping,
  resetDuplicateLinkData,
  setActiveLinkItem,
  resetActiveLink,
  setAssignedAttributes,
} = links.actions;

export default links.reducer;
