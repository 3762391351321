import { useRoutes } from 'react-router-dom';

import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import CommonRoutes from './CommonRoutes';
import ELinkRoutes from './ELinkRoutes';

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, AdminRoutes, CommonRoutes, ELinkRoutes]);
}
