import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { noop } from 'lodash';

export const getMyUserData = createAsyncThunk('auth/getMyUserData', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await axiosInstance.get('/me');

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProfileData = createAsyncThunk(
  'auth/updateProfileData',
  async ({ userNewData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/profile/update', userNewData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfilePicture = createAsyncThunk(
  'auth/updateProfilePicture',
  async ({ newProfilePicture = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/profile/update/photo', newProfilePicture, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteProfilePicture = createAsyncThunk('auth/updateProfilePicture', async (rejectWithValue) => {
  try {
    const response = await axiosInstance.post('/profile/delete/photo', { photo: null });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateProfilePassword = createAsyncThunk(
  'auth/updateProfilePassword',
  async ({ updatePassParams = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/profile/password', updatePassParams);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDomainInformation = createAsyncThunk(
  'auth/updateDomainInformation',
  async ({ domainId = '', updatedObj = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/user/domains/${domainId}`, updatedObj);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDomainDefaultQRTemplate = createAsyncThunk(
  'auth/updateDomainInformation',
  async ({ domainId = '', templateID = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${domainId}/qr/template/${templateID}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserDomainInformation = createAsyncThunk(
  'auth/getUserDomainInformation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/user/domains');

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMyProfileData = createAsyncThunk('auth/getMyProfileData', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await axiosInstance.get('/profile');

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getMyDomainsList = createAsyncThunk('auth/getMyDomainsList', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/profile/domains`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const logOut = createAsyncThunk('auth/logOut', async (onSuccess = noop, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/logout');
    onSuccess();

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const userModifyAPI = {
  getUserAllRoles() {
    return axiosInstance.get('/roles').then(res => res.data);
  },
  getUserRolesDomain(userID) {
    return axiosInstance.get(`/roles/user/${userID}`).then(res => res.data);
  },
  getUserInfo(userID) {
    return axiosInstance.get(`/users/${userID}`).then(res => res.data);
  },
  updateUserRoles(userID, userRolesData = {}) {
    return axiosInstance.put(`/roles/user/${userID}`, userRolesData).then(res => res.data);
  },
  lockUser(userID) {
    return axiosInstance.post(`/users/lock/${userID}`).then(res => res.data);
  },
  unLockUser(userID) {
    return axiosInstance.post(`/users/unlock/${userID}`).then(res => res.data);
  },
};

export const updateUserRefreshToken = async ({ refresh_token = '' }) => {
  const {
    data: { token = '' },
  } = await axiosInstance.post('/update-refresh', { refresh_token });

  return token;
};

export const getTimezoneOptions = async () => axiosInstance.get(`/timezones`).then(res => res.data);
