import { lazy } from 'react';
import AuthGuard from 'middleware/AuthGuard';
import MainLayout from 'layout/MainLayout';
import loadable from 'components/Loadable';
import { Navigate } from 'react-router';

const AdminAccountUsers = loadable(lazy(() => import('pages/admin/account-users')));
const AdminAddNewUser = loadable(lazy(() => import('pages/admin/add-new-user')));
const AdminAttribute = loadable(lazy(() => import('pages/admin/admin-attribute')));
const EditUserRoles = loadable(lazy(() => import('pages/admin/edit-user-roles')));
const AdminLinkTemplates = loadable(lazy(() => import('pages/admin/admin-link-templates')));
const AdminQRTemplates = loadable(lazy(() => import('pages/admin/admin-qr-templates')));
const AdminTags = loadable(lazy(() => import('pages/admin/admin-tags')));
const AdminIpExclusions = loadable(lazy(() => import('pages/admin/ip-exclusions')));
const AdminTrustedDestinations = loadable(lazy(() => import('pages/admin/trusted-destinations')));
const AdminPendingLinks = loadable(lazy(() => import('pages/admin/pending-links')));

const AdminRoutes = {
  path: '/',
  children: [
    {
      path: '/admin',
      element: <Navigate to="/admin/attributes" />,
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'attributes',
          element: <AdminAttribute />,
        },
        {
          path: 'users',
          element: <AdminAccountUsers />,
        },
        {
          path: 'link-templates',
          element: <AdminLinkTemplates />,
        },
        {
          path: 'qr-templates',
          element: <AdminQRTemplates />,
        },
        {
          path: 'tags',
          element: <AdminTags />,
        },
        {
          path: 'pending-links',
          element: <AdminPendingLinks />,
        },
        {
          path: 'trusted-destinations',
          element: <AdminTrustedDestinations />,
        },
        {
          path: 'users/add',
          element: <AdminAddNewUser />,
        },
        {
          path: 'users/:userID/roles',
          element: <EditUserRoles />,
        },
        {
          path: 'ip-exclusions',
          element: <AdminIpExclusions />,
        },
      ],
    },
  ],
};

export default AdminRoutes;
