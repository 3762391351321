import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../utils/axios';

export const sendFCMToken = async tokenFCM =>
  axiosInstance
    .post('/users/firebase', {
      firebase_token: tokenFCM,
    })
    .then(res => res.data);

export const getNotificationsList = createAsyncThunk(
  'notifications/getNotificationsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/notifications`, {
        params: {
          per_page: 500,
          sort: 'created_at',
          sort_direction: 'desc',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const readAllNotifications = createAsyncThunk(
  'notifications/readAllNotifications',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        notifications: { notificationsList = [] },
      } = getState();
      const notificationIDList = notificationsList.map(({ uuid }) => uuid);

      const response = await axiosInstance.put(`/notifications/read/`, {
        notifications: notificationIDList,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async ({ notificationID }, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/notifications/${notificationID}`);
      return { notificationID };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
